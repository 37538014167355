


































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IFundLatestGradeDto } from '@/interfaces/dto/IFundLatestGradeDto';
import { IResearchView } from '@/interfaces/entity/IClientEntity';
const SHOW_COUNT = 20;
const SHOW_COUNT_INCREMENT = 20;
@Component({})
export default class ResearchManagers extends Vue {
    @Prop({ type: String, required: false }) managerName?: string;

    @Prop({ type: Object, required: false }) researchView?: IResearchView | null

    @Prop({ type: Object, required: false, default: null }) previouslySelectedManager?: IFundLatestGradeDto | null;

    public fundsWithLatestGrade: Array<IFundLatestGradeDto> = [];

    public localManagerName = '';

    public selectedManager: IFundLatestGradeDto | null = null;

    public loading = false;

    public deleteManagerAssociation = false;

    displayIssue = '';

    public search = '';

    public radioFilter = 'all';

     public SHOW_COUNT = Object.freeze(SHOW_COUNT);

    public SHOW_COUNT_INCREMENT = Object.freeze(SHOW_COUNT_INCREMENT);

    public showCount = SHOW_COUNT;

    get showCountText (): number{
        return this.showCount < this.filteredListLength ? this.showCount : this.filteredListLength;
    }

    increaseCount (): void {
        this.showCount += SHOW_COUNT_INCREMENT;
    }

    updateShowCount (): void {
        this.showCount = SHOW_COUNT;
    }

    get showMoreButton (): boolean {
        return this.showCount < this.filteredListLength;
    }

    get filteredListLength (): number {
        return this.filteredList.length;
    }

    get searchToLowerCase (): string {
        return this.search.toLowerCase();
    }

    get filteredList (): Array<IFundLatestGradeDto> {
        return this.fundsWithLatestGrade.filter((fund) => {
            return fund.fundInfo.fundName.toLowerCase().includes(this.searchToLowerCase) || fund.fundInfo.managerName.toLowerCase().includes(this.searchToLowerCase);
        });
    }

    created (): void {
        this.localManagerName = this.managerName;
        this.fundsWithLatestGrade = this.$store.state.fundsWithLatestGrade;
    }

    public updateManager (manager: IFundLatestGradeDto): void {
        this.selectedManager = manager;
    }

    public deleteAssociation (): void {
        this.deleteManagerAssociation = true;
        this.selectedManager = null;
    }

    public selectManager (): void {
        if (this.deleteManagerAssociation) this.$emit('delete-association');
        if (!this.selectedManager) this.$emit('close-modal');
        else this.$emit('select-manager', this.selectedManager);
    }

    get activeSelection (): IFundLatestGradeDto | null {
        if (this.selectedManager) return this.selectedManager;
        else if (this.previouslySelectedManager && this.previouslySelectedManager.fundInfo.managerId) return this.previouslySelectedManager;
        return null;
    }
}

