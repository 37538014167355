






































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import TextInputWithFormattedOverlay from '@/components/inputs/TextInputWithFormattedOverlay.vue';
import { ITickerAvailabilityDto } from '@/interfaces/dto/ITickerAvailabilityDto';

import TickerValueType from '@/components/TickerValueType.vue';
import { IAvailableStreamDto } from '@/interfaces/dto/IAvailableStreamDto';
const SHOW_COUNT = 20;
const SHOW_COUNT_INCREMENT = 20;

@Component({
    components: {
        TextInputWithFormattedOverlay,
        TickerValueType
    },
})
export default class TickerDetailsListAvailable extends Vue {
    @Prop({ required: false, type: String }) tickerId: string;

    @Prop({ required: false, type: String }) tickerValueType: string;

    public tickerValueLocal: string | null = null;

    public tickerValueTypeLocal: string | null = null;

    public selectedOption: null | ITickerAvailabilityDto = null;

    public search = '';

    public SHOW_COUNT = Object.freeze(SHOW_COUNT);

    public SHOW_COUNT_INCREMENT = Object.freeze(SHOW_COUNT_INCREMENT);

    public showCount = SHOW_COUNT;

    get showCountText (): number{
        return this.showCount < this.filteredListLength ? this.showCount : this.filteredListLength;
    }

    increaseCount (): void {
        this.showCount += SHOW_COUNT_INCREMENT;
    }

    updateShowCount (): void {
        this.showCount = SHOW_COUNT;
    }

    get showMoreButton (): boolean {
        return this.showCount < this.filteredListLength;
    }

    get filteredListLength (): number {
        return this.filteredList.length;
    }

    get searchToLowerCase (): string {
        return this.search.toLowerCase();
    }

    get filteredList (): ReadonlyArray<ITickerAvailabilityDto> {
        return this.availableTickers.filter((ticker) => {
            return (
                ticker.ticker.toLowerCase().includes(this.searchToLowerCase) ||
                ticker.description.toLowerCase().includes(this.searchToLowerCase)
            );
        });
    }

    get availableTickers (): ReadonlyArray<ITickerAvailabilityDto> {
        return this.$store.state.availableTickers;
    }

    mounted (): void {
        if (this.tickerId) {
            this.tickerValueLocal = this.tickerId;
            this.selectedOption = this.availableTickers.find((ticker) => ticker.ticker === this.tickerId);
        }
        if (this.tickerValueType) {
            this.tickerValueTypeLocal = this.tickerValueType;
        }
    }

    @Watch('selectedOption', { immediate: true })
    updateLocalTicker (ticker: ITickerAvailabilityDto): void {
        this.tickerValueLocal = ticker ? ticker.ticker : null;
    }

    cardClick (ticker: ITickerAvailabilityDto): void {
        if (this.selectedOption === ticker) {
            this.selectedOption = null;
            this.tickerValueTypeLocal = null;
        } else {
            this.selectedOption = ticker;
            this.tickerValueTypeLocal = ticker.availableStreams[0].valueType;
        }
    }

    get tickerValueTypeOptionsById (): ReadonlyArray<string> {
        const ticker = this.$store.state.availableTickers.find((ticker: ITickerAvailabilityDto) => ticker.ticker === this.tickerValueLocal);
        if (ticker) return ticker.availableStreams.map((stream: IAvailableStreamDto) => {
            return stream.valueType;
        });
        return [];
    }

    get selectedAvailableStreamIndex (): number {
        return this.selectedOption.availableStreams.findIndex((stream) => stream.valueType === this.tickerValueTypeLocal);
    }

    get dateRangeString (): string {
        this.selectedOption;
        this.selectedAvailableStreamIndex;
        if (this.selectedOption && this.selectedAvailableStreamIndex !== -1) {
            let startDate = new Date(this.selectedOption.availableStreams[this.selectedAvailableStreamIndex].startDate);
            let endDate = new Date(this.selectedOption.availableStreams[this.selectedAvailableStreamIndex].endDate);
            return `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()} - ${endDate.getDate()}/${endDate.getMonth() + 1
            }/${endDate.getFullYear()}`;
        }
        return '';
    }


    public saveChanges (): undefined {
        if (!this.selectedOption) return;
        this.$emit('selectTicker', this.selectedOption.ticker, this.tickerValueTypeLocal);
    }
}
