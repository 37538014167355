import { IExposureDto } from '@/interfaces/dto/IExposureDto';
import ApiHelper from './apiHelper';
import { IHierarchyDataDto } from '@/interfaces/dto/IHierarchyDataDto';
import { IClientEntity } from '@/interfaces/entity/IClientEntity';


export default class HierarchyApi {
    static async getClientHierarchyData (loadData: { clientName: string; }): Promise<IHierarchyDataDto> {
        try {
            const { data } = await ApiHelper.post('GetClientHierarchyData', loadData);
            return data as IHierarchyDataDto;
        } catch (error) {
            //error
        }
    }

    static async saveData (saveData: any): Promise<any> {
        try {
            const { data } = await ApiHelper.post('UpdateClientRelationships', saveData);
            return data;
        } catch (error) {
            //error
        }
    }

    static async updateEntityFixedRelationships (payload: IExposureDto): Promise<IClientEntity> {
        return (await ApiHelper.post('UpdateEntityFixedRelationships', payload)).data as IClientEntity;
    }


    static async update (updateData: any): Promise<any> {
        try {
            const { data } = await ApiHelper.post('CreateClientHierachyData', updateData);
            return data;
        } catch (error) {
            //error
        }
    }
}
