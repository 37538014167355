var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tickerValueTypeOptionsById.length
    ? _c("selectInput", {
        attrs: {
          label: _vm.label,
          value: _vm.value,
          options: _vm.tickerValueTypeOptionsById,
          disabled: _vm.disabled,
        },
        on: { "update:change": _vm.updateValue },
      })
    : _c("small", [_vm._v("Select a valid ticker")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }