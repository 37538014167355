













import { Component, Vue, Prop } from 'vue-property-decorator';
import SelectInput from '@/components/inputs/SelectInput.vue';
import { ITickerAvailabilityDto } from '@/interfaces/dto/ITickerAvailabilityDto';
import { IAvailableStreamDto } from '@/interfaces/dto/IAvailableStreamDto';

@Component({
    components: {
        SelectInput
    },
})
export default class TickerValueType extends Vue {

    @Prop({ type: String, required: true }) value: string;

    @Prop({ type: String, required: true }) tickerId: string;

    @Prop({ required: false, type: String }) label: string;

    @Prop({ required: false, type: Boolean, default: false }) disabled: boolean;

    get tickerValueTypeOptionsById (): Array<string> {
        const ticker = this.$store.state.availableTickers.find((ticker: ITickerAvailabilityDto) => ticker.ticker === this.tickerId);
        if (ticker) {
            return ticker.availableStreams.map((stream: IAvailableStreamDto) => {
                return stream.valueType;
            });
        }
        return [];
    }

    public updateValue (value: string): void {
        this.$emit('update:change',  value);
    }
}
