
































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import DatePickerWrapper from '@/components/DatePickerWrapper.vue';
import EffectiveDate from '@/components/EffectiveDate.vue';
import ResearchManagers from '@/components/ResearchManagers.vue';
import TextInputWithFormattedOverlay from '@/components/inputs/TextInputWithFormattedOverlay.vue';
import TickerDetailsListAvailable from '@/components/TickerDetailsListAvailable.vue';
import TickerValueType from '@/components/TickerValueType.vue';
import { cloneDeep } from 'lodash';
import { IFundLatestGradeDto } from '@/interfaces/dto/IFundLatestGradeDto';
import { ITickerAvailabilityDto } from '@/interfaces/dto/ITickerAvailabilityDto';
import { ITickerDetailData } from '@/interfaces/ticker/ITickerDetailData.js';
import  ITickerAllocationsByDate from '@/interfaces/ticker/ITickerAllocationsByDate';
import { ManagerStrategyFund } from '@/interfaces/ManagerStrategyFund';
import { compareReverse } from '@/utils/compare';
import { TTickerType } from '@/types/TTickerType';
import { IBenchmark, IResearchView, IRollForward, ITickerDetails } from '@/interfaces/entity/IClientEntity';
import BaseModal from '@/components/BaseModal.vue';
import ConfirmDeletion from '@/components/ConfirmDeletion.vue';
import {DEFAULT_TICKER_ALLOCATION_DATE, FORMAT_DATE_UI} from '@/constants';
import {formatDateToISOShort} from '@/utils/formatToISOShortDate';
import { applyMultiCurrencyFeature } from '@/utils/applyMultiCurrencyFeature';

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

const VALID_DECIMAL_PLACES = 5;

@Component({
    components: {
        EffectiveDate,
        TextInputWithFormattedOverlay,
        DatePickerWrapper,
        ResearchManagers,
        TickerDetailsListAvailable,
        TickerValueType,
        BaseModal,
        ConfirmDeletion

    },
})

export default class TickerDetails extends Vue {
    @Prop({type: Array, required: true}) tickerDetailsData: Array<ITickerDetails>;

    @Prop({type: [Object, null], required: false}) benchmarkData: IBenchmark;

    @Prop({type: [Object, null], required: false}) rollForward: IRollForward;

    @Prop({type: String, required: true}) nodeName!: string;

    @Prop({type: Number, required: true}) entityNameId: number;

    @Prop({type: String, required: true}) tickerType: TTickerType;

    @Prop({ type: Number || null, required: true }) portfolioId: number | null;

    @Prop({ type: Object, required: false }) researchView?: IResearchView | null

    @Prop({ type: Object, required: false }) selectedManagerFund?: IFundLatestGradeDto;

    @Prop({ type: Boolean, required: true }) hasSecondaryBenchmark!: boolean;

    @Prop({ type: Boolean, required: true }) isEntityCustomInvestment: boolean;

    public deleteModal = {
        active: false,
        deleteKeyword: this.deleteModalKeyword
    };

    get benchmarkName (): string {
        return this.benchmark?.benchmarkName;
    }

    get applyMultiCurrencyFeatureForTickerDetails (): boolean {
        return applyMultiCurrencyFeature();
    }

    @Watch('benchmarkName')
    onBenchmarkNameChange (): void {
        this.deleteModal.deleteKeyword = this.deleteModalKeyword;
    }

    get deleteModalKeyword (): string {
        return this.modalHeading  + (this.isTickerTypeBenchmark ? '- ' + this.benchmarkName : '');
    }

    deleteBenchmark ():void {
        this.deleteModal.active = true;
    }

    deleteRollForward ():void {
        this.deleteModal.active = true;
    }

    deleteStrategicAllocation ():void {
        this.deleteModal.active = true;
    }

    discardDeleteModal (): void {
        this.deleteModal.active = false;
    }

    confirmDelete ():void {
        if (this.isTickerTypeBenchmark) {
            this.$emit('delete-benchmark');
        } else if (this.isTickerTypeRollForward) {
            this.$emit('delete-roll-forward');
        } else if (this.isTickerTypeStrategicAllocation) {
            this.$emit('delete-strategic-allocation');
        }
        this.discardDeleteModal();
    }

    get showDeleteBenchmark (): boolean {
        if (!this.isTickerTypeBenchmark) return false;
        if (this.benchmark.benchmarkId === null) return false;
        if (this.benchmark.isPrimaryBenchmark) {
            return false;
        }
        return true;
    }

    public dayJs = dayjs();

    public currentDateIndex = 0;

    public showAddNewEffectiveDatePicker = false;

    public showError = false;

    public errorMessages: Array<string> = [];

    public localTickerDetails: Array<ITickerAllocationsByDate> = [];


    public showTickerIdSearch = false;

    @Prop({type: Boolean, required: true}) showManagerResearch: boolean;

    public selectedAllocationIndex = -1;

    public selectedTickerId = '';

    public selectedTickerValueType: string | null = null;


    public waitForTickerFetch = false;

    public waitingToSave = false;

    public localManagerName = '';


    get modalHeading ():string {
        let name = '';
        if (this.isTickerTypeRollForward && this.isEntityCustomInvestment) {
            name = 'Funding Notional Rollforward rule';
        } else {
            name = this.modalTypeName;
        }

        return  `${name} for ${this.nodeName}`;
    }


    get tickerIdsArray (): string[] {
        return this.$store.state.availableTickers.map((ticker: ITickerAvailabilityDto) =>  ticker.ticker);
    }

    get tableHeadings (): {name: string; key: string; tooltipContent: string;}[] {
        const baseHeadings = [
            { name: 'Ticker Id', key: 'tickerId', tooltipContent: 'Click the search icon to view all tickers' },
            { name: 'Value Type', key: 'valueType', tooltipContent: 'Choose the ticker Value Type' },
            { name: 'Proportion', key: 'weight', tooltipContent: 'Must total 100%' },
            { name: 'Return Multiple', key: 'returnMultiple', tooltipContent: '' },
            { name: 'Premium', key: 'premium', tooltipContent: '' }
        ];

        if (this.applyMultiCurrencyFeatureForTickerDetails) {
            baseHeadings.push(
                { name: 'Ticker Currency', key: 'tickerBaseCurrency', tooltipContent: '' },
                { name: `Convert to ${this.getPortfolioBaseCurrency()}?`, key: 'shouldConvertToBaseCurrency', tooltipContent: 'If ticked then the ticker currency will be converted to the portfolio base currency to calculate returns' }
            );
        }

        return baseHeadings;
    }

    getTickerTooltip (ticker: string): string {
        if (this.doesTickerExist(ticker)) return ticker;
        else return 'Ticker not found';
    }

    doesTickerExist (id: string):boolean {
        return this.tickerIdsArray.some((tickerId) => tickerId === id);
    }

    handleDataList (event: Event, allocationIndex: number):void {
        const tickerId = (event.target as HTMLInputElement).value.trim().toUpperCase();
        this.updateTickerAllocations(allocationIndex, 'tickerId', tickerId);
    }

    benchmark: IBenchmark = {
        benchmarkId: null,
        benchmarkName: '',
        benchmarkPieces: [],
        isPrimaryBenchmark: false,
        entityNameId: -1,
        id: -1
    };

    handlePrimary (): void {
        this.benchmark.isPrimaryBenchmark = true;
    }

    created (): void {
        this.benchmark = JSON.parse(JSON.stringify(this.benchmarkData));
        let tickerByDate: Record<string, Array<ITickerDetailData>> = {};

        if (this.tickerDetailsData !== undefined) {
            this.tickerDetailsData.forEach((data) => {
                if (tickerByDate[data.effectiveDate] === undefined) {
                    tickerByDate[data.effectiveDate] = [];
                }
                const tickerData: ITickerDetailData = {
                    ...data,
                    tickerBaseCurrency: this.getTickerBaseCurrency (data.tickerId)
                };
                tickerByDate[data.effectiveDate].push(tickerData);
            });
        }

        let tickerByDateArray: Array<ITickerAllocationsByDate> = [];

        for (var d in tickerByDate) {
            tickerByDateArray.push({
                effectiveDate: d,
                tickerAllocations: tickerByDate[d],
            });
        }

        this.localTickerDetails = cloneDeep(tickerByDateArray);
        this.localManagerName = this.nodeName;
    }

    public getPortfolioBaseCurrency (): string | null {
        return this.$store.state.selectedScheme.defaultCurrency || null;
    }

    public getTickerBaseCurrency (tickerId: string): string | null {
        const newTicker = this.$store.state.availableTickers.find((availableTicker: ITickerAvailabilityDto) => availableTicker.ticker === tickerId) || null;
        return newTicker?.baseCurrency || null;
    }

    public selectEffectiveDate (index: number): void {
        if (this.initValidationMode(false)) return;
        this.currentDateIndex = index;
    }

    public addNewEffectiveDate (): void {
        if (this.initValidationMode(false)) return;
        if (this.localTickerDetails.length === 0) {
            this.updateNewEffectiveDate(DEFAULT_TICKER_ALLOCATION_DATE);
        } else {
            this.showAddNewEffectiveDatePicker = true;
        }

    }

    public updateExistingEffectiveDate (effectiveDate: { dateIndex: number; date: string; }): void {
        Vue.set(this.localTickerDetails[effectiveDate.dateIndex], 'effectiveDate', effectiveDate.date);
        if (this.isTickerTypeStrategicAllocation) {
            Vue.set(this.localTickerDetails[effectiveDate.dateIndex].tickerAllocations[0], 'effectiveDate', effectiveDate.date);
        } else {
            this.localTickerDetails[effectiveDate.dateIndex].tickerAllocations.forEach((e: ITickerDetailData) => {
                e.effectiveDate = effectiveDate.date;
            });
        }
        this.currentDateIndex = this.sortedEffectiveDate.findIndex((d: ITickerAllocationsByDate) => d.effectiveDate === effectiveDate.date);
    }

    public isConvertibleToBaseCurrency (allocation: ITickerDetailData): boolean {
        if (!allocation.tickerBaseCurrency || allocation.tickerBaseCurrency === 'n/a') {
            return false;
        }
        return allocation.tickerBaseCurrency.toUpperCase() !== this.$store.state.selectedScheme.defaultCurrency.toUpperCase();
    }

    public updateNewEffectiveDate (effectiveDate: string): void {
        if (this.isTickerTypeStrategicAllocation) {
            this.localTickerDetails.push({
                effectiveDate: effectiveDate,
                tickerAllocations: [{
                    allocationValue: 0,
                    effectiveDate,
                }]
            });
        } else {
            const defaultTicker: ITickerAvailabilityDto | undefined = this.$store.state.availableTickers.find((availableTicker: ITickerAvailabilityDto) => availableTicker.ticker === 'FLAT');
            let tickerId = '';
            let valueType = '';
            let tickerBaseCurrency = '';

            if (defaultTicker) {
                tickerId = defaultTicker.ticker;
                valueType = defaultTicker.availableStreams[0].valueType;
                tickerBaseCurrency = defaultTicker.baseCurrency;
            }
            this.localTickerDetails.push({
                effectiveDate: effectiveDate,
                tickerAllocations: [{
                    effectiveDate: effectiveDate,
                    entityNameId: this.entityNameId,
                    premium: 0,
                    returnMultiple: 1,
                    tickerId,
                    valueType,
                    weight: 1,
                    shouldConvertToBaseCurrency: false, // false as ticker id is Flat and base currency is n/a
                    tickerBaseCurrency,
                }],
            });
        }

        this.showAddNewEffectiveDatePicker = false;
        const newEffectiveDateIndex = this.sortedEffectiveDate.findIndex((details: ITickerAllocationsByDate) => details.effectiveDate === effectiveDate);
        this.currentDateIndex = newEffectiveDateIndex;
    }


    initValidationMode (doTickerLengthCheck: boolean): boolean {
        let error = false;
        let messagesArr: Array<string> = [];

        if (this.isTickerTypeBenchmark) {
            if (this.benchmark.benchmarkName.trim().length < 3) {
                error = true;
                messagesArr.push('Benchmark name must be at least 3 characters long');
            }
            if (this.benchmark.benchmarkName.trim().toLowerCase() === 'sum-weighted benchmark') {
                error = true;
                messagesArr.push('sum-weighted benchmark is a reserved name');
            }
        }

        if (this.tickerType !== 'strategicAllocation') {
            if (this.localTickerDetails.length === 0 && doTickerLengthCheck) {
                error = true;
                messagesArr.push('Please add an effective date');
            }

            if (this.localTickerDetails.length > 0 && !this.allocationErrorCheck.proportionTotal) {
                error = true;
                messagesArr.push('Proportion must sum to 100%');
            }

            if (this.localTickerDetails.length > 0 && this.allocationErrorCheck.tickerId !== -1) {
                error = true;
                messagesArr.push('Ticker Id must not be an empty value');
            }


            if (this.localTickerDetails.length > 0) {
                const tickerIds: string[] = [];
                const tickerValueTypes: string[] = [];
                const tickerShouldConvertToBaseCurrency: boolean[] = [];
                this.localTickerDetails[this.currentDateIndex].tickerAllocations.forEach((allocation: ITickerDetailData) => {
                    const { tickerId, valueType, weight, shouldConvertToBaseCurrency, tickerBaseCurrency } = allocation;
                    if (weight > 99.99 || weight < -99.99) {
                        error = true;
                        messagesArr.push('Weight must be in range -9,999% to 9,999%');
                    }
                    if (!this.doesTickerExist(tickerId)){
                        error = true;
                        messagesArr.push(`Ticker Id ${tickerId} could not be found.`);
                    }
                    // No error message thrown for identical Ticker Ids chosen multiple times and have different "shouldConvertToBaseCurrency"
                    const matchingIndices = tickerIds.reduce((acc, id, index) => {
                        if (id === tickerId) {
                            acc.push(index);
                        }
                        return acc;
                    }, [] as number[]);

                    matchingIndices.forEach(index => {
                        if (tickerValueTypes[index] === valueType) {
                            let conversionStatusMessage = '';
                            if (tickerShouldConvertToBaseCurrency[index]) {
                                conversionStatusMessage = `to be converted to ${this.getPortfolioBaseCurrency()}`;
                            } else {
                                conversionStatusMessage = `remaining as ${tickerBaseCurrency}`;
                            }
                            if (tickerShouldConvertToBaseCurrency[index] === shouldConvertToBaseCurrency) {
                                error = true;
                                messagesArr.push(`Duplicate entry: Ticker ID ${tickerId} with ${valueType}, ${conversionStatusMessage}`);
                            }
                        }
                    });
                    tickerIds.push(tickerId);
                    tickerValueTypes.push(valueType);
                    tickerShouldConvertToBaseCurrency.push(shouldConvertToBaseCurrency);
                });

            }
        } else {
            this.localTickerDetails.forEach((t: ITickerAllocationsByDate) => {
                if (t.tickerAllocations[0].allocationValue > 1 || t.tickerAllocations[0].allocationValue < 0) {
                    error = true;
                }
            });
            if (error) {

                messagesArr.push('Allocation must be in range 0 to 100%');
            }
        }

        this.showError = error;
        this.errorMessages = messagesArr;

        if (error) {
            this.$nextTick().then(() => {
                this.$refs.errorContainer && (this.$refs.errorContainer as HTMLDivElement)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
        }

        return error;
    }

    isDeleteDateDisabled = false

    public toggleDeleteDateDisabled (bool: boolean): void {
        this.isDeleteDateDisabled = bool;
    }

    public deleteEffectiveDate (dateIndex: number): void {
        Vue.delete(this.localTickerDetails, dateIndex);
        this.currentDateIndex && this.showError ? (this.showError = false) : this.showError;
        this.currentDateIndex = this.localTickerDetails.length - 1;
    }

    public addTickerAllocations (): void {
        // if (this.initValidationMode()) return;
        this.localTickerDetails[this.currentDateIndex].tickerAllocations.push({
            benchmarkPieceId: 0,
            effectiveDate: this.localTickerDetails[this.currentDateIndex].effectiveDate,
            entityNameId: this.entityNameId,
            premium: 0,
            returnMultiple: 1,
            tickerId: '',
            weight: 1,
            valueType: '',
            tickerBaseCurrency: '',
            shouldConvertToBaseCurrency: false, // false as ticker id is empty
        });
    }

    private replacePercentAndMultiplySymbol (value: string, property: string) {
        let parsedValue: number;

        if (property === 'weight' || property === 'allocationValue') {
            value = value.replace('%', '');
            parsedValue = parseFloat(value) / 100;
        } else {
            value = value.replace('x', '');
            parsedValue = parseFloat(value);
        }

        return parsedValue;
    }

    public updateStrategicAllocation (value: string): void {
        let allocationValue: number;
        allocationValue = value.includes('%') ? this.replacePercentAndMultiplySymbol(value, 'allocationValue') : parseFloat(value) / 100;
        allocationValue = isNaN(allocationValue) ? 0 : allocationValue;
        Vue.set(this.localTickerDetails[this.currentDateIndex].tickerAllocations[0], 'allocationValue', allocationValue);
    }

    public updateTickerAllocations (allocationIndex: number, property: string, value: string | boolean): void {
        if (property === 'shouldConvertToBaseCurrency') {
            Vue.set(this.localTickerDetails[this.currentDateIndex].tickerAllocations[allocationIndex], property, value);
        } else if (typeof value === 'string') {
            let tickerValue: number | string = value;
            if (property !== 'valueType' && (tickerValue.includes('%') || tickerValue.includes('x')) && property !== 'tickerId') {
                tickerValue = this.replacePercentAndMultiplySymbol(tickerValue, property);
            }
            if (property === 'weight' || property === 'premium') tickerValue = parseFloat(value) / 100;
            if (property === 'returnMultiple' && tickerValue === '') tickerValue = 1;
            if ((property === 'premium' && isNaN(tickerValue as number)) || (property === 'weight' && isNaN(tickerValue as number))) tickerValue = 0;
            if (property === 'tickerId' && !isNaN(tickerValue as number)) {
                tickerValue = '';
                Vue.set(this.localTickerDetails[this.currentDateIndex].tickerAllocations[allocationIndex], 'valueType', '');
                Vue.set(this.localTickerDetails[this.currentDateIndex].tickerAllocations[allocationIndex], 'tickerBaseCurrency', 'n/a');
                Vue.set(this.localTickerDetails[this.currentDateIndex].tickerAllocations[allocationIndex], 'shouldConvertToBaseCurrency', false);
            }
            if (property === 'tickerId') {

                tickerValue = value.toString().toUpperCase();

                const isTickerValid = this.doesTickerExist(tickerValue);

                let validTickerFirstValueType = '';
                if (isTickerValid) {
                    const x = this.$store.state.availableTickers.find(
                        (ticker: ITickerAvailabilityDto) => ticker.ticker.toLowerCase() === tickerValue.toString().toLowerCase()
                    );
                    validTickerFirstValueType = x.availableStreams[0].valueType;
                    const oldTickerId = this.localTickerDetails[this.currentDateIndex].tickerAllocations[allocationIndex].tickerId;
                    if (oldTickerId !== tickerValue) {
                        const newTicker: ITickerAvailabilityDto | null = this.$store.state.availableTickers.find((availableTicker: ITickerAvailabilityDto) => availableTicker.ticker === tickerValue) || null;
                        const newBaseCurrency = newTicker.baseCurrency;
                        Vue.set(this.localTickerDetails[this.currentDateIndex].tickerAllocations[allocationIndex], 'tickerBaseCurrency', newBaseCurrency);
                    }
                }
                Vue.set(this.localTickerDetails[this.currentDateIndex].tickerAllocations[allocationIndex], 'valueType', validTickerFirstValueType);

                Vue.set(this.localTickerDetails[this.currentDateIndex].tickerAllocations[allocationIndex], 'shouldConvertToBaseCurrency', this.isConvertibleToBaseCurrency(this.localTickerDetails[this.currentDateIndex].tickerAllocations[allocationIndex]));

            }

            Vue.set(this.localTickerDetails[this.currentDateIndex].tickerAllocations[allocationIndex], property, tickerValue);
            if (!this.allocationErrorCheck.proportionTotal || this.allocationErrorCheck.tickerId !== -1) {
                this.showError = false;
            }
        }
    }

    get showMainDisplay (): boolean {
        return !this.showTickerIdSearch && !this.showManagerResearch;
    }

    public deleteTickerAllocations (allocationIndex: number): void {
        Vue.delete(this.localTickerDetails[this.currentDateIndex].tickerAllocations, allocationIndex);
        this.showError ? (this.showError = false) : this.showError;
    }

    get isTickerTypeStrategicAllocation (): boolean {
        return this.tickerType === 'strategicAllocation';
    }

    get isTickerTypeBenchmark (): boolean {
        return this.tickerType === 'benchmark';
    }

    get isTickerTypeRollForward (): boolean {
        return this.tickerType === 'rollForward';
    }

    doesTickerAllocationContainDefaultDate (tickerAllocations: ITickerAllocationsByDate[]): boolean {
        return tickerAllocations.some((tickerAllocation: ITickerAllocationsByDate) => formatDateToISOShort(tickerAllocation.effectiveDate) === DEFAULT_TICKER_ALLOCATION_DATE);
    }

    doesTickerAllocationContainDuplicateDates (tickerAllocations: ITickerAllocationsByDate[]): boolean {
        const dates: string[] = [];
        return tickerAllocations.some((tickerAllocation: ITickerAllocationsByDate) => {
            if (dates.includes(formatDateToISOShort(tickerAllocation.effectiveDate))) return true;
            dates.push(formatDateToISOShort(tickerAllocation.effectiveDate));
            return false;
        });
    }


    saveChanges (): void {
        if (this.waitForTickerFetch) {
            this.waitingToSave = true;
            return;
        }
        this.waitingToSave = false;
        if (this.initValidationMode(true)) return;


        if (this.isTickerTypeStrategicAllocation) {
            let returnArray: Array<ITickerDetailData> = [];
            this.localTickerDetails.forEach((t: ITickerAllocationsByDate) => {
                const { allocationValue, effectiveDate } = t.tickerAllocations[0];
                returnArray.push({ allocationValue, effectiveDate });
            });

            this.$emit('saveAllocationChanges', returnArray);
        } else if (this.isTickerTypeRollForward) {

            const hasDefaultDate = this.doesTickerAllocationContainDefaultDate(this.localTickerDetails);
            if (!hasDefaultDate) {
                this.showError = true;
                this.errorMessages.push(`Rollforward must have one effective date of ${dayjs(DEFAULT_TICKER_ALLOCATION_DATE).format(FORMAT_DATE_UI)}`);
                return;
            }
            const hasDuplicateDates = this.doesTickerAllocationContainDuplicateDates(this.localTickerDetails);
            if (hasDuplicateDates) {
                this.showError = true;
                this.errorMessages.push('Rollforward cannot have duplicate effective dates');
                return;
            }

            let returnArray: Array<ITickerDetails> = [];
            this.localTickerDetails.forEach((t: ITickerAllocationsByDate) => {
                t.tickerAllocations.forEach((a: ITickerDetailData) => {
                    returnArray.push({
                        benchmarkPieceId: a.benchmarkPieceId,
                        effectiveDate: t.effectiveDate,
                        entityNameId: this.entityNameId,
                        premium: a.premium,
                        id: a.id,
                        returnMultiple: a.returnMultiple,
                        tickerId: a.tickerId,
                        valueType: a.valueType,
                        weight: a.weight,
                        shouldConvertToBaseCurrency: a.shouldConvertToBaseCurrency,
                    });
                });
            });

            this.$emit('saveRollForward', returnArray);
        } else if (this.isTickerTypeBenchmark) {
            this.showError = false;
            this.errorMessages = [];
            const hasDefaultDate = this.doesTickerAllocationContainDefaultDate(this.localTickerDetails);
            if (!hasDefaultDate) {
                this.showError = true;
                this.errorMessages.push(`Benchmark must have one effective date of ${dayjs(DEFAULT_TICKER_ALLOCATION_DATE).format(FORMAT_DATE_UI)}`);
                return;
            }
            const hasDuplicateDates = this.doesTickerAllocationContainDuplicateDates(this.localTickerDetails);
            if (hasDuplicateDates) {
                this.showError = true;
                this.errorMessages.push('Benchmark cannot have duplicate effective dates');
                return;
            }
            let returnArray: Array<ITickerDetails> = [];
            this.localTickerDetails.forEach((t: ITickerAllocationsByDate) => {
                t.tickerAllocations.forEach((a: ITickerDetailData) => {
                    returnArray.push({
                        benchmarkPieceId: a.benchmarkPieceId,
                        effectiveDate: t.effectiveDate,
                        entityNameId: this.entityNameId,
                        premium: a.premium,
                        id: a.id,
                        returnMultiple: a.returnMultiple,
                        tickerId: a.tickerId,
                        valueType: a.valueType,
                        weight: a.weight,
                        shouldConvertToBaseCurrency: a.shouldConvertToBaseCurrency,
                    });
                });

            });

            this.benchmark.benchmarkPieces = returnArray;

            this.$emit('saveBenchMarkChanges', this.benchmark);
        }
    }

    get allocationErrorCheck (): { proportionTotal: boolean; tickerId: number } {
        return {
            proportionTotal: parseFloat(this.allocationProportionTotal.toFixed(VALID_DECIMAL_PLACES))*100 === 100,
            tickerId: this.localTickerDetails[this.currentDateIndex].tickerAllocations.findIndex((allocation: ITickerDetailData) => allocation.tickerId === ''),
        };
    }

    get activeTickerAllocationDetails (): Array<ITickerDetailData> {
        return this.localTickerDetails[this.currentDateIndex]?.tickerAllocations;
    }

    get sortedEffectiveDate (): Array<ITickerAllocationsByDate> {
        return this.localTickerDetails.sort(compareReverse);
    }

    get allocationProportionTotal (): number {
        return this.localTickerDetails[this.currentDateIndex].tickerAllocations.reduce((acc: number, allocation: ITickerDetailData) => acc + allocation.weight, 0);
    }


    get modalTypeName ():  TTickerType | string {
        let modalNames = {
            strategicAllocation: 'Strategic Allocation',
            benchmark: 'Benchmark',
            rollForward: 'Rollforward',
            entityManagerMapping: 'Manager Association',
        };

        return modalNames[this.tickerType] || '';
    }

    public toggleTickerModal (bool: boolean | undefined, allocationIndex: number, tickerValue: string | null, tickerValueType: string | null): void {
        this.showTickerIdSearch = bool !== undefined ? bool : !this.showTickerIdSearch;
        this.$emit('updateShowTickerIdSearch', this.showTickerIdSearch);
        this.selectedAllocationIndex = allocationIndex;
        this.selectedTickerId = tickerValue;
        this.selectedTickerValueType = tickerValueType;
    }

    public selectTicker (tickerId: string, tickerValueType: string): void {
        this.updateTickerAllocations(this.selectedAllocationIndex, 'tickerId', tickerId);
        this.updateTickerAllocations(this.selectedAllocationIndex, 'valueType', tickerValueType);
        this.toggleTickerModal(false, -1, null, null);
    }

    public selectManager (selectedManager: ManagerStrategyFund): void {
        this.$emit('saveManagerAssociation', selectedManager);
    }

    public deleteAssociation (): void {
        this.$emit('deleteAssociation');
    }

    public closeManagerModal (): void {
        this.$emit('hideManagerModal', null);
    }

    @Watch('waitForTickerFetch', { immediate: true })
    readyToSave (): void {
        if (!this.waitForTickerFetch && this.waitingToSave) {
            this.saveChanges();
        }
    }
}
