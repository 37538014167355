


















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import dayjs from 'dayjs';
import {FORMAT_DATE_UI, FORMAT_DATE_ISO_SHORT, DEFAULT_TICKER_ALLOCATION_DATE} from '@/constants';
import ITickerAllocationsByDate from '@/interfaces/ticker/ITickerAllocationsByDate';

@Component({
    components: {
        DatePicker,
    },
})
export default class DatePickerWrapper extends Vue {
    @Prop({type: Array, required:false, default: function ():Array<ITickerAllocationsByDate> {
        return [];
    }}) tickerAllocations!: Array<ITickerAllocationsByDate>;

    @Prop({type:Number, required:true}) currentDateIndex!: number;

    format = FORMAT_DATE_UI;

    valueType = FORMAT_DATE_ISO_SHORT;

    public DATE_TODAY = dayjs().format(FORMAT_DATE_ISO_SHORT);

    public DEFAULT_DATE = dayjs(DEFAULT_TICKER_ALLOCATION_DATE).format(FORMAT_DATE_ISO_SHORT);

    public DEFAULT_DATE_UI = dayjs(DEFAULT_TICKER_ALLOCATION_DATE).format(FORMAT_DATE_UI);


    shortcuts = [
        {
            text: 'Today',
            onClick: ():void => {
                this.updateDate(this.DATE_TODAY);
            },
        },
        {
            text: this.DEFAULT_DATE_UI,
            onClick: ():void => {
                this.updateDate(this.DEFAULT_DATE);
            },
        },
    ];


    public dayJs = dayjs();

    @Emit('updateEffectiveDate')
    updateDate (date: string): string {
        return date;
    }

    // array of dates that are already in use, and today, and should be disabled
    disabledDates (date: Date): boolean {
        const dateNow = dayjs();
        let isDateExcluded = false;
        const thisDate = dayjs(date);
        this.tickerAllocations.forEach((tickerAllocation) => {
            if (dayjs(tickerAllocation.effectiveDate).isSame(thisDate)) isDateExcluded = true;
        });

        return thisDate > dateNow || isDateExcluded;
    }

    get localDate ():string{
        // if not found (eg, -1), must be a new date
        return this.tickerAllocations[this.currentDateIndex]?.effectiveDate ?? '';
    }
}
