var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "benchmark-rules" },
    [
      _c("table", [
        _vm._m(0),
        _c(
          "tbody",
          _vm._l(_vm.exposures, function (exposure) {
            return _c(
              "tr",
              {
                key: exposure.id,
                class: { loading: _vm.isExposureAPIPending },
              },
              [
                _c(
                  "td",
                  [
                    _c("BaseButton", {
                      staticClass: "text-nowrap",
                      attrs: {
                        type: "button",
                        tooltipText: "Edit",
                        disabled: exposure.id === null,
                        buttonClass: "edit-btn",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openExposureById(exposure.id)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [_vm._v(" " + _vm._s(exposure.name) + " ")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    exposure.rollForwards
                      ? _c("font-awesome-icon", {
                          staticClass: "check",
                          attrs: { icon: ["fal", "check"] },
                        })
                      : _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "times"] },
                        }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    exposure.benchmarks.length
                      ? _c("font-awesome-icon", {
                          staticClass: "check",
                          attrs: { icon: ["fal", "check"] },
                        })
                      : _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "times"] },
                        }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    exposure.strategicAllocations.length
                      ? _c("font-awesome-icon", {
                          staticClass: "check",
                          attrs: { icon: ["fal", "check"] },
                        })
                      : _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "times"] },
                        }),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    exposure.researchView
                      ? _c("font-awesome-icon", {
                          staticClass: "check",
                          attrs: { icon: ["fal", "check"] },
                        })
                      : _c("font-awesome-icon", {
                          attrs: { icon: ["fal", "times"] },
                        }),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "text-right actions" },
                  [
                    _c("BaseButton", {
                      attrs: { type: "button", tooltipText: "Edit" },
                      on: {
                        click: function ($event) {
                          return _vm.openExposureById(exposure.id)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  staticClass: "action-icon",
                                  attrs: { icon: ["fal", "edit"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("BaseButton", {
                      attrs: { type: "button", tooltipText: "Delete" },
                      on: {
                        click: function ($event) {
                          return _vm.showConfirmDeleteModal(
                            exposure.id,
                            exposure.name
                          )
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("font-awesome-icon", {
                                  staticClass: "action-icon",
                                  attrs: { icon: ["fal", "trash-alt"] },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
        _c("tfoot", [
          _c("tr", [
            _c(
              "td",
              [
                _c("BaseButton", {
                  staticClass: "text-nowrap",
                  attrs: {
                    type: "button",
                    tooltipText: "Edit",
                    buttonClass: "edit-btn",
                  },
                  on: { click: _vm.openFundingNotional },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function () {
                        return [_vm._v("Funding Notional")]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "td",
              [
                _c("font-awesome-icon", {
                  staticClass: "check",
                  attrs: { icon: ["fal", "check"] },
                }),
              ],
              1
            ),
            _c("td", { staticClass: "check" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.BENCHMARK_RULES_TEXT,
                      expression: "BENCHMARK_RULES_TEXT",
                    },
                  ],
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fal", "check"] },
                  }),
                  _c("sup", [_vm._v("*")]),
                ],
                1
              ),
            ]),
            _c("td", [_vm._v("–")]),
            _c("td", [_vm._v("–")]),
            _c(
              "td",
              { staticClass: "text-right actions" },
              [
                _c("BaseButton", {
                  attrs: { type: "button", tooltipText: "Edit" },
                  on: { click: _vm.openFundingNotional },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("font-awesome-icon", {
                            staticClass: "action-icon",
                            attrs: { icon: ["fal", "edit"] },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c("BaseButton", {
                  attrs: { type: "button", disabled: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("font-awesome-icon", {
                            staticClass: "action-icon",
                            attrs: { icon: ["fal", "trash-alt"] },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("BaseButton", {
        attrs: { buttonClass: "edit-btn" },
        on: {
          click: function ($event) {
            return _vm.openExposureById(null)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("font-awesome-icon", {
                  staticClass: "action-icon",
                  attrs: { icon: ["fal", "plus"] },
                }),
                _vm._v(" add new exposure"),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.deleteModal.active
        ? _c("BaseModal", {
            class: { "show-modal": _vm.deleteModal.active },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "ConfirmDeletion",
                        {
                          attrs: {
                            "delete-keyword": _vm.deleteModal.deleteKeyword,
                          },
                          on: {
                            discardDeleteModal: _vm.discardDeleteModal,
                            confirmDelete: _vm.confirmDelete,
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "You're about to delete " +
                                _vm._s(_vm.deleteModal.deleteKeyword) +
                                ", this will also delete all associated performance overrides."
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4090406131
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-left", staticStyle: { width: "25%" } }, [
          _vm._v("Name"),
        ]),
        _c("th", { staticClass: "text-left", staticStyle: { width: "15%" } }, [
          _vm._v("Rollforward rule"),
        ]),
        _c("th", { staticClass: "text-left", staticStyle: { width: "15%" } }, [
          _vm._v("Benchmark rules"),
        ]),
        _c("th", { staticClass: "text-left", staticStyle: { width: "15%" } }, [
          _vm._v("Strategic benchmark allocations"),
        ]),
        _c("th", { staticClass: "text-left", staticStyle: { width: "15%" } }, [
          _vm._v("Research View"),
        ]),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }