var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn btn--default sum-weight-btn",
      attrs: { type: "button" },
      on: { click: _vm.setSumWeightedAsPrimaryBenchmark },
    },
    [_vm._v(" Make Primary ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }