var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "control-wrapper" },
    [
      _vm.showClientSetup
        ? _c("BaseModal", {
            staticClass: "show-modal modal-m",
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "client-setup-page" },
                        [
                          _c("client-setup-view", {
                            attrs: {
                              "is-initial-setup-page": true,
                              "client-setup-with-options":
                                _vm.clientSetupWithOptions,
                            },
                            on: { "close-client-setup": _vm.closeClientSetup },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1528624479
            ),
          })
        : _vm._e(),
      _vm.deleteModal.active
        ? _c("BaseModal", {
            class: { "show-modal modal-s": _vm.deleteModal.active },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("ConfirmDeletion", {
                        attrs: {
                          "delete-keyword": _vm.deleteModal.deleteKeyword,
                        },
                        on: {
                          discardDeleteModal: _vm.discardDeleteModal,
                          confirmDelete: _vm.confirmDelete,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              902135495
            ),
          })
        : _vm._e(),
      _vm.isLoading
        ? _c("LoadingOverlay", { attrs: { "is-fetching-data": _vm.isLoading } })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "tree grid-item",
          class: { "edit-mode": _vm.isEditMode },
        },
        [
          _c("ejs-treeview", {
            attrs: {
              id: "treeview",
              fields: _vm.fields,
              "allow-drag-and-drop": true,
              "selected-nodes": _vm.selectedNode
                ? [_vm.selectedNode.id]
                : _vm.treeViewRootNodeId,
              "node-clicked": _vm.nodeClicked,
              itemid: "id",
            },
            on: { nodeDragStop: _vm.dragStop, nodeDragging: _vm.nodeDrag },
          }),
        ],
        1
      ),
      _vm.isHierarchyEdited
        ? _c(
            "div",
            { staticClass: "page-options grid-item save-hierarchy-options" },
            [
              _c("p", [
                _vm._v(
                  "You can continue to edit and save all your hierarchy changes once you are ready."
                ),
              ]),
              _c(
                "div",
                { staticClass: "save-hierarchy flex flex--row-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "btn btn--default discard",
                      on: { click: _vm.discardHierarchyChanges },
                    },
                    [_vm._v("Discard")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "btn btn--default save",
                      on: { click: _vm.saveHierarchy },
                    },
                    [_vm._v("Save")]
                  ),
                ]
              ),
            ]
          )
        : _vm.isEditMode
        ? _c(
            "div",
            { staticClass: "page-options grid-item" },
            [
              _vm.editingNode
                ? _c("edit-entity", {
                    key: _vm.editingNode.id,
                    attrs: {
                      "editing-node-obj": _vm.editingNode,
                      "portfolio-id": _vm.portfolioId,
                      entityNames: _vm.entityNames,
                      selectedNodeExposureEntities:
                        _vm.selectedNodeExposureEntities,
                    },
                    on: {
                      close: _vm.closeEditEntity,
                      "save-new": _vm.saveNew,
                      "create-exposure": _vm.createExposure,
                      "open-exposure-by-id": _vm.openExposureById,
                      "delete-exposure-by-id": _vm.deleteExposureById,
                      "update-entity": _vm.updateClientEntities,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "page-options grid-item" },
            [
              _vm._m(0),
              _c("BaseDivider"),
              _c(
                "section",
                [
                  _c("action-text", {
                    staticClass: "action",
                    attrs: {
                      icon: "plus",
                      disabled: _vm.isCustomInvestment,
                      text: "Add new standard investment to grouping",
                    },
                    on: { customclick: _vm.createStandardInvestment },
                  }),
                  _c("action-text", {
                    staticClass: "action",
                    attrs: {
                      icon: "pencil",
                      disabled: !_vm.isStandardInvestment,
                      text:
                        "Edit selected standard investment" +
                        (_vm.isStandardInvestment
                          ? " - " + _vm.selectedNode.name
                          : ""),
                    },
                    on: { customclick: _vm.editStandardInvestment },
                  }),
                  _c("action-text", {
                    staticClass: "action",
                    attrs: {
                      icon: "trash",
                      disabled: !_vm.isStandardInvestment,
                      text:
                        "Delete selected standard investment" +
                        (_vm.isStandardInvestment
                          ? " - " + _vm.selectedNode.name
                          : ""),
                    },
                    on: { customclick: _vm.setDeleteModal },
                  }),
                ],
                1
              ),
              _vm.hasCustomInvestments
                ? _c(
                    "div",
                    [
                      _c("BaseDivider"),
                      _c(
                        "section",
                        [
                          _c("action-text", {
                            staticClass: "action",
                            attrs: {
                              icon: "pencil",
                              disabled: !_vm.isCustomInvestment,
                              text:
                                "Edit selected custom investment" +
                                (_vm.isCustomInvestment
                                  ? " - " + _vm.selectedNode.name
                                  : ""),
                            },
                            on: { customclick: _vm.editCustomInvestment },
                          }),
                          _c("action-text", {
                            staticClass: "action",
                            attrs: {
                              icon: "trash",
                              disabled:
                                !_vm.isCustomInvestment ||
                                _vm.doesSelectedNodeHaveExposures,
                              text:
                                "Delete selected custom investment" +
                                (_vm.isCustomInvestment
                                  ? " - " + _vm.selectedNode.name
                                  : "") +
                                " - must have no exposures",
                            },
                            on: { customclick: _vm.setDeleteModal },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("BaseDivider"),
              _c(
                "section",
                [
                  _c("action-text", {
                    staticClass: "action",
                    attrs: {
                      icon: "plus",
                      disabled:
                        _vm.isStandardInvestment || _vm.isCustomInvestment,
                      text: "Create hierarchy grouping",
                    },
                    on: { customclick: _vm.createGrouping },
                  }),
                  _c("action-text", {
                    staticClass: "action",
                    attrs: {
                      icon: "pencil",
                      disabled:
                        _vm.isStandardInvestment || _vm.isCustomInvestment,
                      text:
                        "Edit hierarchy grouping" +
                        (_vm.isGrouping ? " - " + _vm.selectedNode.name : ""),
                    },
                    on: { customclick: _vm.editGrouping },
                  }),
                  _c("action-text", {
                    staticClass: "action",
                    attrs: {
                      icon: "trash",
                      disabled:
                        !_vm.isGrouping || _vm.doesSelectedNodeHaveChildren,
                      text:
                        "Delete hierarchy grouping" +
                        (_vm.isGrouping ? " - " + _vm.selectedNode.name : "") +
                        " - must have no dependencies",
                    },
                    on: { customclick: _vm.setDeleteModal },
                  }),
                ],
                1
              ),
            ],
            1
          ),
      !_vm.hierarchyData && !_vm.isLoading
        ? _c("base-modal", {
            class: {
              "show-modal modal-s": !_vm.hierarchyData && !_vm.isLoading,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "fund-not-found-message" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "icon",
                            attrs: { icon: ["fa", "exclamation-triangle"] },
                          }),
                          _c("p", [
                            _vm._v(
                              "There was an error in getting your setup information."
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              "Please contact the support team for assistance."
                            ),
                          ]),
                          _c("button", { on: { click: _vm.logout } }, [
                            _vm._v("Go back"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              581198368
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h1", [_vm._v("Adding and editing investments and managers")]),
      _c("p", [
        _vm._v(
          "Your investment hierarchy is shown on the left. You can use the drag and drop functionality to make changes to its structure."
        ),
      ]),
      _c("p", [
        _vm._v(
          " The actions below allow the adding and editing of investments and groupings to the hierarchy. "
        ),
        _c("b", [
          _vm._v(
            "You need to select hierarchy groups to add investments to them."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }