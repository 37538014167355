






































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import DatePickerWrapper from '@/components/DatePickerWrapper.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import dayjs from 'dayjs';
import ITickerAllocationsByDate from '@/interfaces/ticker/ITickerAllocationsByDate';

@Component({
    components: {
        DatePicker,
        DatePickerWrapper,
    },
})
export default class EffectiveDate extends Vue {
  @Prop({type: Array, required: true}) details!: Array<ITickerAllocationsByDate>;

  @Prop({type: String, required: true}) effectiveDate!: string;

  @Prop({type: Number, required:true}) dateIndex!: number;

  @Prop({type: Number, required:true}) currentDateIndex!: number;

  @Prop({type: Boolean, required: false }) disabled!: boolean;

  public showConfirmDelete = false;

  get isTickerSelected (): boolean {
      return this.currentDateIndex === this.dateIndex;
  }

  get effectiveDateCount ():number {
      return this.details.length;
  }

  setShowConfirmDelete (val: boolean): undefined {
      if (this.currentDateIndex !== this.dateIndex) return;
      this.showConfirmDelete = val;
  }

  public dayJs = dayjs;

  public editDate = false;

  get effectiveDateFormatted (): string {
      return dayjs(this.effectiveDate).format('DD MMM YYYY');
  }

  public enableEditing (): void {
      if (this.currentDateIndex !== this.dateIndex) return;
      this.editDate = true;
  }

  cancelDatePicker (): void {
      this.editDate = false;
  }

  @Watch('showConfirmDelete')
  onShowConfirmDeleteChange (val: boolean): void {
      this.$emit('toggleDeleteDateDisabled', val);
  }

  @Watch('isTickerSelected')
  onTickerSelectedChange (val: boolean): void {
      if (!val) {
          this.editDate = false;
          this.showConfirmDelete = false;
          this.$emit('toggleDeleteDateDisabled', false);
      }
  }

  @Emit('updateExistingEffectiveDate')
  editEffectiveDate (value: string): { dateIndex: number, date: string }{
      this.editDate = false;
      return { dateIndex: this.dateIndex, date: value };
  }

  @Emit('deleteEffectiveDate')
  deleteDate (): number {
      if (this.currentDateIndex !== this.dateIndex) return;
      this.showConfirmDelete = false;
      return this.dateIndex;
  }

  @Emit('selectEffectiveDate')
  selectDate (): number {
      return this.dateIndex;
  }

  public notAfterToday (date: string): boolean {
      const dateNow = dayjs();
      return dayjs(date) > dateNow;
  }
}
