var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.editDate
        ? _c(
            "div",
            { staticClass: "flex effective-date" },
            [
              _c(
                "button",
                {
                  staticClass: "date-text inner-content cursor-pointer",
                  attrs: { type: "button" },
                  on: { click: _vm.selectDate },
                },
                [_vm._v(" " + _vm._s(_vm.effectiveDateFormatted) + " ")]
              ),
              _vm.isTickerSelected && !_vm.disabled
                ? [
                    !_vm.showConfirmDelete
                      ? [
                          _vm.effectiveDateCount > 1
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "ticker-table-icon inner-content",
                                  attrs: {
                                    type: "button",
                                    title: "Mark for deletion",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setShowConfirmDelete(true)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fal", "trash-alt"] },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "button",
                            {
                              staticClass: "ticker-table-icon inner-content",
                              attrs: { type: "button", title: "Edit" },
                              on: { click: _vm.enableEditing },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fal", "edit"] },
                              }),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "transition",
                            { attrs: { name: "fade", appear: "" } },
                            [
                              _c("div", { staticClass: "confirm-delete-row" }, [
                                _c(
                                  "span",
                                  { staticClass: "confirm-delete-text" },
                                  [
                                    _vm._v(
                                      " Delete " +
                                        _vm._s(_vm.effectiveDateFormatted) +
                                        "? "
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "ticker-table-icon inner-content",
                                      attrs: { type: "button", title: "Undo" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setShowConfirmDelete(false)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["fal", "undo"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "ticker-table-icon inner-content",
                                      attrs: {
                                        type: "button",
                                        title: "Delete",
                                      },
                                      on: { click: _vm.deleteDate },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["fal", "check"] },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.editDate
        ? _c("DatePickerWrapper", {
            attrs: {
              tickerAllocations: _vm.details,
              currentDateIndex: _vm.currentDateIndex,
            },
            on: {
              cancelDatePicker: _vm.cancelDatePicker,
              updateEffectiveDate: _vm.editEffectiveDate,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }