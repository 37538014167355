var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "manager-details-wrapper" }, [
    _c("div", { staticClass: "manager-details-heading flex" }, [
      _c("h2", [
        _vm._v(
          _vm._s(_vm.localManagerName) + " - Manager Research association"
        ),
      ]),
    ]),
    _vm.loading
      ? _c(
          "div",
          { staticClass: "loader-container" },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fal", "spinner"], spin: true },
            }),
          ],
          1
        )
      : _c("div", { staticClass: "content-box" }, [
          _c("div", { staticClass: "selection-wrapper" }, [
            _c("p", { staticClass: "selection-association" }, [
              !_vm.activeSelection || _vm.deleteManagerAssociation
                ? _c("span", [_vm._v("No Manager Association Set")])
                : _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.activeSelection.fundInfo.managerId
                            ? "Selected"
                            : "Current"
                        ) +
                        " association: "
                    ),
                  ]),
              _vm.selectedManager && _vm.selectedManager.fundInfo.managerId
                ? _c("span", { staticClass: "text-right" }, [
                    _vm._v("(select and save to update)"),
                  ])
                : _vm._e(),
            ]),
            _vm.activeSelection && !_vm.deleteManagerAssociation
              ? _c("div", { staticClass: "card selected active" }, [
                  _c("div", { staticClass: "space-between" }, [
                    _c("div", [
                      _c("p", [
                        _vm._v(" Name: "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.activeSelection.fundInfo.fundName)),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v(" Manager name: "),
                        _c("span", { staticClass: "italic" }, [
                          _vm._v(
                            _vm._s(_vm.activeSelection.fundInfo.managerName)
                          ),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Research group: " +
                            _vm._s(
                              _vm.activeSelection.fundInfo.researchGroup ||
                                "not set"
                            )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "card__rating" }, [
                      _c("p", [
                        _vm._v(
                          "Approval status: " +
                            _vm._s(
                              _vm.activeSelection.latestGrade
                                ? _vm.activeSelection.latestGrade.approvalStatus
                                : "not set"
                            )
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Asset class: " +
                            _vm._s(
                              _vm.activeSelection.fundInfo.assetClass ||
                                "not set"
                            )
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Overall grade: " +
                            _vm._s(
                              _vm.activeSelection.latestGrade
                                ? _vm.activeSelection.latestGrade.overallGrade
                                : "not set"
                            )
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn remove-association",
                      class: { disabled: !_vm.activeSelection },
                      attrs: {
                        type: "button",
                        title: "Delete Association",
                        disabled: !_vm.activeSelection,
                      },
                      on: { click: _vm.deleteAssociation },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fal", "trash-alt"] },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "top-bar" }, [
            _c("div", { staticClass: "search-wrapper" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.search,
                    expression: "search",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "search",
                attrs: {
                  type: "text",
                  placeholder: "Type to filter Managers, Strategies & Funds...",
                },
                domProps: { value: _vm.search },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.search = $event.target.value.trim()
                    },
                    _vm.updateShowCount,
                  ],
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "radio-row" }, [
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Showing " +
                      _vm._s(_vm.showCountText) +
                      " of " +
                      _vm._s(_vm.filteredListLength) +
                      " results"
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "selectors-row" }, [
            !_vm.filteredListLength
              ? _c("p", [
                  _vm._v(
                    "No managers match " +
                      _vm._s(_vm.search) +
                      ", please try again"
                  ),
                ])
              : _c(
                  "div",
                  [
                    _c(
                      "transition-group",
                      { attrs: { name: "fade", mode: "out-in", tag: "div" } },
                      _vm._l(
                        _vm.filteredList.slice(0, _vm.showCount),
                        function (item) {
                          return _c(
                            "div",
                            {
                              key: item.fundInfo.fundId,
                              staticClass: "card card--list",
                              class: { selected: _vm.selectedManager === item },
                              attrs: { tabindex: "0" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateManager(item)
                                },
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.updateManager(item)
                                },
                              },
                            },
                            [
                              _c("div", [
                                _c("p", [
                                  _vm._v(" Name: "),
                                  _c("strong", [
                                    _vm._v(_vm._s(item.fundInfo.fundName)),
                                  ]),
                                ]),
                                _c("p", [
                                  _vm._v(" Manager name: "),
                                  _c("span", { staticClass: "italic" }, [
                                    _vm._v(_vm._s(item.fundInfo.managerName)),
                                  ]),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "Research group: " +
                                      _vm._s(
                                        item.fundInfo.researchGroup || "not set"
                                      )
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "card__rating" }, [
                                _vm.researchView &&
                                item.fundInfo.fundResearchEntityId ===
                                  _vm.researchView.researchEntityId
                                  ? _c("p", { staticClass: "selected-text" }, [
                                      _vm._v(" current selection "),
                                    ])
                                  : _vm._e(),
                                _c("p", [
                                  _vm._v(
                                    "Approval status: " +
                                      _vm._s(
                                        item.latestGrade
                                          ? item.latestGrade.approvalStatus
                                          : "not set"
                                      )
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "Asset class: " +
                                      _vm._s(
                                        item.fundInfo.assetClass
                                          ? item.fundInfo.assetClass
                                          : "not set"
                                      )
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "Overall grade: " +
                                      _vm._s(
                                        item.latestGrade
                                          ? item.latestGrade.overallGrade
                                          : "not set"
                                      )
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _vm.showMoreButton
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn--next",
                            attrs: { type: "button" },
                            on: { click: _vm.increaseCount },
                          },
                          [
                            _vm._v(
                              "Next " +
                                _vm._s(_vm.SHOW_COUNT_INCREMENT) +
                                " results"
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
          ]),
        ]),
    _c("div", { staticClass: "flex details-bottom" }, [
      _c(
        "div",
        {
          staticClass: "btn btn--default discard",
          on: {
            click: function ($event) {
              return _vm.$emit("close-modal")
            },
          },
        },
        [_vm._v("Cancel")]
      ),
      _c(
        "div",
        { staticClass: "btn btn--default", on: { click: _vm.selectManager } },
        [_vm._v("Save")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }