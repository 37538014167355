










import { Vue,  Component } from 'vue-property-decorator';

@Component
export default class ButtonMakePrimary extends Vue {
    public setSumWeightedAsPrimaryBenchmark (): void {
        this.$emit('make-primary');
    }
}
