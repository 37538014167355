var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-picker-holder" },
    [
      _c("date-picker", {
        attrs: {
          format: _vm.format,
          "value-type": _vm.valueType,
          "disabled-date": _vm.disabledDates,
          "default-value": _vm.DATE_TODAY,
          clearable: false,
          value: _vm.localDate,
          shortcuts: _vm.shortcuts,
        },
        on: { change: _vm.updateDate },
      }),
      _c(
        "span",
        {
          staticClass: "ticker-table-icon inner-content times-icon",
          on: {
            click: function ($event) {
              return _vm.$emit("cancelDatePicker")
            },
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fal", "times"] } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }