























































































































































import { Component, Vue } from 'vue-property-decorator';
import {
    DragAndDropEventArgs,
    NodeClickEventArgs,
    TreeViewPlugin,
} from '@syncfusion/ej2-vue-navigations';
import HierarchyApi from '@/api/hierarchyApi';
import EntityDataApi from '@/api/entityDataApi';
import ClientSetupApi from '@/api/clientSetupApi';
import ActionText from '@/components/MonitorManager/ActionText.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import EditEntity from '@/components/HierarchyFunctions/EditEntity.vue';
import BaseModal from '@/components/BaseModal.vue';
import BaseDivider from '@/components/BaseElements/BaseDivider.vue';
import ConfirmDeletion from '@/components/ConfirmDeletion.vue';
import cloneDeep from 'lodash.clonedeep';
import dayjs from 'dayjs';
import HierarchyTier from '@/models/hierarchy/HierarchyTier';
import { ICreateHierarchyByDateDto, IHierarchyByDateDto, IHierarchyByDateRelationshipDto, IHierarchyDataDto } from '@/interfaces/dto/IHierarchyDataDto';
import { IClientEntity } from '@/interfaces/entity/IClientEntity.js';
import { IClientEntityDto } from '@/interfaces/dto/IClientEntityDto';
import { TEntityTypeDto } from '@/interfaces/dto/IEntityTypeDto';
import { IExposureDto } from '@/interfaces/dto/IExposureDto';
import { ICreateClientEntityAndSetDefaultsRequestDto } from '@/interfaces/dto/ICreateClientEntityAndSetDefaultsRequestDto';
import ManagerSettingsBreadcrumb from '@/components/ManagerSettingsBreadcrumb.vue';
import { IManagerSettingsBreadcrumb } from '@/interfaces/IManagerSettingsBreadcrumb';
import ClientSetupView from '@/views/ClientSetupView.vue';
import { IClientSetupWithOptionsDto } from '@/interfaces/dto/IClientSetupWithOptionsDto';

Vue.use(TreeViewPlugin);

function getEntitySVG (entityTypeId: TEntityTypeDto): string {
    let svg = '';
    switch (entityTypeId) {
    case 2: {
        svg = '/imgs/fa-briefcase.svg';
        break;
    }
    case 4: {
        svg = '/imgs/fa-sitemap.svg';
        break;
    }
    case 5: {
        svg = '/imgs/fa-user-chart.svg';
        break;
    }
    case 6: {
        svg = '/imgs/fa-scale.svg';
        break;
    }
    default: {
        svg = '/imgs/fa-notdef.svg';
        break;
    }
    }
    return svg;
}

function mapChildren (dataAppend: HierarchyTier, relationships:IHierarchyByDateRelationshipDto[], entities:IClientEntity[] ) {
    const nodeChildren = relationships.filter(
        (r) => r.parentEntityNameId === dataAppend.nodeData.id
    );

    if (nodeChildren.length > 0) {
        const hObj: HierarchyTier[] = [];

        nodeChildren.forEach((child, ix: number) => {
            const thisNodeChildren = relationships.filter(
                (r) => r.parentEntityNameId === child.childEntityNameId
            );
            const childEntity = entities.find(
                (r) => r.id === child.childEntityNameId
            );
            const fixedRelationshipsChildIds = childEntity?.fixedRelationships?.childIds ?? [];
            const fixedRelationshipsChildrenNodeData: IClientEntity[] = entities.filter((e) => fixedRelationshipsChildIds.includes(e.id));
            const data: HierarchyTier = {
                text: childEntity.name,
                name: childEntity.name,
                id: child.clientRelationshipsId,
                childId: child.childEntityNameId,
                parentId: child.parentEntityNameId,
                datePeriodId: child.datePeriodId,
                portfolioId: dataAppend.portfolioId,
                expanded: false,
                entityTypeId: childEntity.entityTypeId,
                child: undefined,
                nodeData: cloneDeep(childEntity),
                orderIndex: child.childEntityNameOrderBy || ix,
                imageUrl: getEntitySVG(childEntity.entityTypeId),
                exposures: fixedRelationshipsChildrenNodeData,
            };

            if (thisNodeChildren.length > 0) {
                data.expanded = true;
                data.child = [];
                mapChildren(data, relationships, entities);
            }

            hObj.push(data);
        });
        dataAppend.child = hObj.sort(sortHierarchyTierChildren);
    }
}

function getNode (id: number, data: HierarchyTier[], nodeObj: HierarchyTier | null, prop: 'childId' | 'id'):HierarchyTier {
    data.forEach((node) => {
        if (nodeObj === null) {
            if (node[prop] === id) {
                nodeObj = node;
            } else if (node.child !== undefined && node.child.length > 0) {
                nodeObj = getNode(id, node.child, nodeObj, prop);
            }
        }
    });

    return nodeObj;
}


function sortHierarchyTierChildren (a:HierarchyTier, b:HierarchyTier) {
    return a.orderIndex - b.orderIndex;
}

function sortHierarchyByDateRelationship (a:IHierarchyByDateRelationshipDto, b:IHierarchyByDateRelationshipDto) {
    return a.childEntityNameOrderBy - b.childEntityNameOrderBy;
}

const newNode: IClientEntity = {
    id: null,
    name: '',
    entityTypeId: null,
    strategicAllocations: [],
    rollForwards: null,
    researchView: null,
    benchmarks: [],
    fixedRelationships: null
};

@Component({
    components: {
        ActionText,
        EditEntity,
        BaseModal,
        LoadingOverlay,
        ConfirmDeletion,
        BaseDivider,
        ManagerSettingsBreadcrumb,
        ClientSetupView
    },
})
export default class ManagerSettings extends Vue {
    public isLoading = false;

    public selectedNode: HierarchyTier | null = null;

    public selectedNodeParent: HierarchyTier | null = null;

    public isEditMode = false;

    public isHierarchyEdited = false;

    public editingNode: IClientEntity | null = null;

    public hierarchyDataClone: IHierarchyByDateDto[] | null = null;

    public hierarchyData: IHierarchyByDateDto[] | null = null;

    public hierarchyDataRoot: number = null;

    public clientEntities: Array<IClientEntity> | null = null;

    public changeId = -10;

    public deleteModal = {
        active: false,
        deleteKeyword: '',
    };

    public showClientSetup = false;

    public clientSetupWithOptions : IClientSetupWithOptionsDto = null;

    get clientEntityNameDisplayName (): string {
        return this.$store.state.clientEntityNameDisplayName;
    }

    created (): void {
        this.checkIfClientSetupRequired();
        this.getViewData();
        this.$store.dispatch('getAvailableTickers');
        this.$store.dispatch('getFundsWithLatestGrade');
    }

    beforeDestroy (): void {
        const breadcrumb = [{
            id: -1,
            name: this.clientEntityNameDisplayName,
        }];

        this.$store.dispatch('updateBreadcrumb', breadcrumb);

    }

    get selectedNodeExposureEntities (): IClientEntity[] {
        if (this.selectedNode === null) return [];
        return this.selectedNode.exposures;
    }

    get entityNames (): string[] {
        if (this.clientEntities === null) return [];
        return this.clientEntities.map((e) => e.name);

    }

    get hasCustomInvestments (): boolean {
        if (this.clientEntities === null) return false;
        return this.clientEntities.some(x => x.entityTypeId == 6);
    }

    public async getViewData (): Promise<void> {
        this.isLoading = true;

        const schemeRequest = {
            clientName: this.clientEntityNameDisplayName,
        };

        const hData: IHierarchyDataDto = await HierarchyApi.getClientHierarchyData(schemeRequest);

        if (hData) {
            const { rootId } = hData;
            let portfolioId = 0;
            hData.hierarchyByDate.forEach((h) => h.relationships.forEach((r) => r.parentId === rootId ? portfolioId = r.childEntityNameId : '' ));
            this.$store.state.selectedScheme.pendingPortfolioId = portfolioId; // update id
        }

        const eData = await EntityDataApi.getData() as IClientEntityDto[];


        if (eData === undefined || hData === undefined) {
            this.isLoading = false;
            return;
        };

        this.hierarchyData = hData.hierarchyByDate.sort((a: IHierarchyByDateDto, b: IHierarchyByDateDto) => {
            return dayjs(b.effectiveDate).valueOf() - dayjs(a.effectiveDate).valueOf();
        });

        this.hierarchyDataRoot = hData.rootId;

        this.clientEntities = eData;

        this.updateHierarchyDataClone();

        this.resetSelectedNode();

        this.isLoading = false;
    }

    public async checkIfClientSetupRequired () : Promise<void> {
        if (this.$store.state.selectedScheme.clientEntityID) {
            try {
                const clientSetupWithOptions = await ClientSetupApi.getClientSetupForClient(this.$store.state.selectedScheme.clientEntityID);
                this.clientSetupWithOptions = clientSetupWithOptions;
                this.showClientSetup = !clientSetupWithOptions.clientSetup.isSetupComplete;
            } catch {
                this.$store.dispatch('pushNetworkErrorMessage', 'Error fetching client setup. Please contact the Monitor support team.');
            }
        }
        return;

    }

    resetSelectedNode (): void {
        // set default selected node
        this.selectedNode = this.hierarchyTiers[0];
        this.updateBreadCrumb();

    }

    get hierarchyTiers ():HierarchyTier[] {
        let hObj: HierarchyTier[] = [];

        if (this.hierarchyData == null) return hObj;

        this.hierarchyData.forEach((h) => {
            const effDate = dayjs(h.effectiveDate);
            const clientRootItem = this.clientEntities.find(
                (e) => e.id === this.hierarchyDataRoot
            );
            if (clientRootItem == undefined) return;
            const rootItemNode = h.relationships.find(
                (n) => n.parentEntityNameId === clientRootItem.id
            );
            const hierarchyRootItem = this.clientEntities.find(
                (n) => n.id === rootItemNode.childEntityNameId
            );
            const fixedRelationshipsChildIds = hierarchyRootItem?.fixedRelationships?.childIds ?? [];
            const fixedRelationshipsChildrenNodeData: IClientEntity[] = this.clientEntities.filter((e) => fixedRelationshipsChildIds.includes(e.id));

            const text = `Portfolio (since ${effDate.format('D/M/YYYY')})`;

            const root: HierarchyTier = {
                text: text,
                id: rootItemNode.clientRelationshipsId,
                childId: rootItemNode.childEntityNameId,
                parentId: rootItemNode.parentEntityNameId,
                datePeriodId: rootItemNode.datePeriodId,
                portfolioId: rootItemNode.parentId,
                expanded: true,
                entityTypeId: hierarchyRootItem.entityTypeId,
                child: [],
                nodeData: cloneDeep(hierarchyRootItem),
                orderIndex: rootItemNode.childEntityNameOrderBy,
                imageUrl: getEntitySVG(hierarchyRootItem.entityTypeId),
                exposures: fixedRelationshipsChildrenNodeData,
            };


            mapChildren(root, h.relationships, this.clientEntities);

            hObj.push(root);
        });

        hObj = hObj.sort(sortHierarchyTierChildren);

        return hObj;
    }

    get hierarchyByDateRelationships ():IHierarchyByDateRelationshipDto[] {
        const obj: IHierarchyByDateRelationshipDto[] = [];
        this.hierarchyData.forEach((h) => {
            obj.push(...h.relationships);
        });
        return obj;
    }

    get doesSelectedNodeHaveChildren (): boolean {
        if (this.selectedNode == null) return false;
        return (
            this.hierarchyByDateRelationships.filter(
                (n) => n.parentEntityNameId == this.selectedNode.childId
            ).length > 0
        );
    }

    get doesSelectedNodeHaveExposures (): boolean {
        if (this.selectedNode == null) return false;
        return this.selectedNode.exposures.length > 0;
    }

    get getParentId (): number | null {
        if (this.selectedNode == null) return null;
        const hierarchyByDate = this.hierarchyData.find(
            (h) => h.datePeriodId == this.selectedNode.datePeriodId
        );
        return hierarchyByDate.relationships.find(
            (n) => n.clientRelationshipsId == this.selectedNode.id
        )?.parentEntityNameId;
    }

    get portfolioId (): number | null {
        if (this.clientEntities.length > 1) {
            return this.clientEntities[1].id;
        } else {
            return null;
        }
    }


    get fields (): Record<'dataSource', HierarchyTier[]> {
        return { dataSource: this.hierarchyTiers };
    }

    updateClientEntities (entity: IClientEntity): void {
        const index = this.clientEntities.findIndex((e) => e.id === entity.id);
        if (index === -1) return;
        const clonedEntity = cloneDeep(entity);
        Vue.set(this.clientEntities, index, clonedEntity);
    }

    nodeClicked (args: NodeClickEventArgs): void | false {
        if (this.isEditMode) return false;
        const nodeObj = null as null;
        this.selectedNode = getNode(
            Number(args.node.getAttribute('data-uid')),
            this.hierarchyTiers,
            nodeObj,
            'id'
        );
        this.updateBreadCrumb();
    }

    nodeDrag (args: DragAndDropEventArgs): void {
        let dropTargetNode = null;
        let dragNodeObj = null;
        if (args.dropTarget != null) {
            dropTargetNode = getNode(
                Number(args.dropTarget.getAttribute('data-uid')),
                this.hierarchyTiers,
                dropTargetNode,
                'id'
            );
        }
        if (args.draggedNode != null) {
            dragNodeObj = getNode(
                Number(args.draggedNode.getAttribute('data-uid')),
                this.hierarchyTiers,
                dragNodeObj,
                'id'
            );
        }
        if (!this.canDrop(args, dropTargetNode, dragNodeObj)) {
            args.dropIndicator = 'e-no-drop';
        }
    }

    dragStop (args: DragAndDropEventArgs): void {
        let dropTargetNode:HierarchyTier = null;
        let dragNodeObj:HierarchyTier = null;
        if (args.dropTarget != null) {
            dropTargetNode = getNode(
                Number(args.dropTarget.getAttribute('data-uid')),
                this.hierarchyTiers,
                dropTargetNode,
                'id'
            );
        }
        if (args.draggedNode != null) {
            dragNodeObj = getNode(
                Number(args.draggedNode.getAttribute('data-uid')),
                this.hierarchyTiers,
                dragNodeObj,
                'id'
            );
        }

        if (!this.canDrop(args, dropTargetNode, dragNodeObj)) {
            args.cancel = true;
        } else {
            if (dragNodeObj != null) {
                const hierarchyByDateDto = this.hierarchyData.find(
                    (h) => h.datePeriodId == dragNodeObj.datePeriodId
                );
                hierarchyByDateDto.relationships = hierarchyByDateDto.relationships.filter(function (ele) {
                    return ele.clientRelationshipsId != dragNodeObj.id;
                });

                const targetChildrenIds = dropTargetNode.child
                    ? dropTargetNode.child.map((chld) => chld.childId)
                    : [];
                const untouchedElements:IHierarchyByDateRelationshipDto[] = [];
                let originTierSiblings:IHierarchyByDateRelationshipDto[] = [];
                let targetTierSiblings:IHierarchyByDateRelationshipDto[] = [];

                if (args.draggedNodeData.parentID === args.droppedNodeData.parentID) {
                    hierarchyByDateDto.relationships.map((ele) => {
                        if (targetChildrenIds.indexOf(ele.id) > -1) {
                            targetTierSiblings.push(ele);
                        } else {
                            untouchedElements.push(ele);
                        }
                    });
                } else {
                    hierarchyByDateDto.relationships.map((ele) => {
                        if (ele.parentId === dragNodeObj.parentId) {
                            originTierSiblings.push(ele);
                        } else if (targetChildrenIds.indexOf(ele.id) > -1) {
                            targetTierSiblings.push(ele);
                        } else {
                            untouchedElements.push(ele);
                        }
                    });

                    originTierSiblings = originTierSiblings.sort(sortHierarchyByDateRelationship);
                    originTierSiblings = originTierSiblings.map(
                        (ele, ix: number) => {
                            return { ...ele, childEntityNameOrderBy: ix };
                        }
                    );
                }
                targetTierSiblings = targetTierSiblings.sort(sortHierarchyByDateRelationship);
                targetTierSiblings.splice(args.dropIndex, 0, {
                    clientRelationshipsId: this.changeId,
                    parentEntityNameId: dropTargetNode.childId,
                    childEntityNameId: dragNodeObj.childId,
                    childEntityNameOrderBy: args.dropIndex,
                    datePeriodId: hierarchyByDateDto.datePeriodId,
                    effectiveDate: hierarchyByDateDto.effectiveDate,
                    parentId: dropTargetNode.childId,
                    id: dragNodeObj.childId,
                });
                targetTierSiblings = targetTierSiblings.map((ele, ix: number) => {
                    return { ...ele, childEntityNameOrderBy: ix };
                });

                hierarchyByDateDto.relationships = [
                    ...untouchedElements,
                    ...originTierSiblings,
                    ...targetTierSiblings,
                ];

                this.isHierarchyEdited = true;
                this.changeId -= 1;
                this.selectedNode = null;
                this.selectedNodeParent = null;
            }
        }

        args.cancel = true;
    }

    canDrop (args: DragAndDropEventArgs, dropTargetNode:HierarchyTier, dragNodeObj:HierarchyTier): boolean {
        if (dropTargetNode == null) {
            return false;
        } else if (dragNodeObj.datePeriodId != dropTargetNode.datePeriodId) {
            return false;
        } else if (dropTargetNode != null && dropTargetNode.entityTypeId === 5 || dropTargetNode.entityTypeId === 6) {
            return false;
        } else if (args.draggedNodeData.id === args.droppedNodeData.id) {
            return false;
        } else if (args.draggedNodeData.id === args.droppedNodeData.parentID) {
            return false;
        } else if (!args.droppedNodeData.parentID) {
            return false;
        }
        return true;
    }

    async saveHierarchy (): Promise<void> {
        this.isLoading = true;

        for (const hierarchy of this.hierarchyData) {
            const saveData = {
                hierarchyId: hierarchy.datePeriodId,
                dateEffectiveFrom: hierarchy.effectiveDate,
                hierarchyData: hierarchy.relationships,
            };

            await HierarchyApi.saveData(saveData);
            this.updateHierarchyDataClone();
            this.resetSelectedNode();

        }

        this.isHierarchyEdited = false;
        this.isLoading = false;
    }

    setHierarchyDataFromClone (): void {
        this.hierarchyData = cloneDeep(this.hierarchyDataClone);
    }

    updateHierarchyDataClone (): void {
        this.hierarchyDataClone = cloneDeep(this.hierarchyData);
    }

    async discardHierarchyChanges (): Promise<void> {
        this.setHierarchyDataFromClone();
        this.isHierarchyEdited = false;
    }

    get isPortfolio (): boolean {
        if (this.selectedNode === null) return false;
        else {
            return this.selectedNode.entityTypeId === 2;
        }
    }

    get isGrouping (): boolean {
        if (this.selectedNode === null) return false;
        else {
            return this.selectedNode.entityTypeId === 4;
        }
    }

    get isStandardInvestment (): boolean {
        if (this.selectedNode === null) return false;
        else {
            return this.selectedNode.entityTypeId === 5;
        }
    }

    get isCustomInvestment (): boolean {
        if (this.selectedNode === null) return false;
        else {
            return this.selectedNode.entityTypeId === 6;
        }
    }
    /**
     * Updates the breadcrumb for the ManagerSettings view.
     * The breadcrumb is a navigation trail that shows the hierarchy of selected nodes.
     * It starts with the root node and includes all parent nodes up to the selected node.
     * The breadcrumb is stored in the Vuex store using the 'updateBreadcrumb' action.
     */

    updateBreadCrumb ():void{

        const breadcrumb: IManagerSettingsBreadcrumb[] = [];
        let node = this.selectedNode;

        while (node && node.name) {
            breadcrumb.unshift({
                id: node.id,
                name: node.name
            });
            node = getNode(node.parentId, this.hierarchyTiers, null, 'childId');
        }
        breadcrumb.unshift({
            id: this.hierarchyDataRoot,
            name: this.clientEntityNameDisplayName,
        });

        this.$store.dispatch('updateBreadcrumb', breadcrumb);
    }


    editStandardInvestment (): void {
        this.editingNode = cloneDeep(this.selectedNode.nodeData);
        this.isEditMode = true;
    }

    editCustomInvestment (): void {
        this.editingNode = cloneDeep(this.selectedNode.nodeData);
        this.isEditMode = true;
    }

    openExposureById (exposureId: number ): void {
        const exposure = this.selectedNodeExposureEntities.find((e) => e.id === exposureId);
        this.editingNode = cloneDeep(exposure);
    }

    createExposure (): void {
        const nodeObj = null as null;
        if (this.selectedNode.entityTypeId === 6) {
            this.selectedNodeParent = getNode(
                this.selectedNode.parentId,
                this.hierarchyTiers,
                nodeObj,
                'childId'
            );
        } else {
            this.selectedNodeParent = getNode(
                this.selectedNode.childId,
                this.hierarchyTiers,
                nodeObj,
                'childId'
            );
        }

        this.editingNode = cloneDeep(newNode);
        this.editingNode.entityTypeId = 7;
        this.isEditMode = true;
    }

    createStandardInvestment (): void {
        const nodeObj = null as null;
        if (this.selectedNode.entityTypeId === 5) {
            this.selectedNodeParent = getNode(
                this.selectedNode.parentId,
                this.hierarchyTiers,
                nodeObj,
                'childId'
            );
        } else {
            this.selectedNodeParent = getNode(
                this.selectedNode.childId,
                this.hierarchyTiers,
                nodeObj,
                'childId'
            );
        }

        this.editingNode = cloneDeep(newNode);
        this.editingNode.entityTypeId = 5;
        this.isEditMode = true;
    }

    async deleteExposureById (id:number): Promise<void> {
        this.isLoading = true;
        const childIds: number[] = this.selectedNode.nodeData.fixedRelationships.childIds;
        const newChildIds = childIds.filter((c) => c !== id);
        const payload: IExposureDto = {
            portfolioId: this.portfolioId,
            clientId: this.$store.state.selectedScheme.clientEntityID,
            entityNameId: this.selectedNode.childId,
            childIds: newChildIds,
        };
        try {
            const customExposure = await HierarchyApi.updateEntityFixedRelationships(payload);
            const isDeleteSuccessful =  await EntityDataApi.deleteClientEntity(id);
            const exposureIndex =  this.clientEntities.findIndex((e) => e.id === customExposure.id);
            if (exposureIndex === -1 || !isDeleteSuccessful) return; // if exposure not found or delete not successful, return
            this.clientEntities.splice(exposureIndex, 1, customExposure); // update client entities

            // update selected node to latest exposures
            this.selectedNode = getNode(
                this.selectedNode.childId,
                this.hierarchyTiers,
                null,
                'childId'
            );
            // update editing node to the custom investment
            this.editingNode = cloneDeep(this.selectedNode.nodeData);
        }
        catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error deleting exposure');
            this.closeEditEntity();
        }
        finally {
            this.isLoading = false;
        }
    }

    async deleteFund (): Promise<void> {
        this.isLoading = true;

        const hier = this.hierarchyData.find(
            (h) => h.datePeriodId == this.selectedNode.datePeriodId
        );

        const nodeId = this.selectedNode.id;
        const nodeChildId = this.selectedNode.childId;

        hier.relationships = hier.relationships.filter(function (ele) {
            return ele.clientRelationshipsId != nodeId;
        });

        const saveData = {
            hierarchyId: hier.datePeriodId,
            dateEffectiveFrom: hier.effectiveDate,
            hierarchyData: hier.relationships,
        };

        await HierarchyApi.saveData(saveData);

        const otherRelationShips = this.hierarchyByDateRelationships.filter(
            (n) => n.childEntityNameId == nodeChildId
        );

        if (otherRelationShips.length === 0) {
            try {
                await EntityDataApi.deleteClientEntity(nodeChildId);
            }
            catch (error) {
                this.$store.dispatch('pushNetworkErrorMessage', 'Error deleting entity');
                this.closeEditEntity();
            }

            this.clientEntities = this.clientEntities.filter(function (ele) {
                return ele.id != nodeChildId;
            });
        }

        this.isLoading = false;
        this.isEditMode = false;
        this.editingNode = null;
        this.selectedNodeParent = null;
        this.resetSelectedNode();
    }

    editGrouping (): void {
        this.editingNode = cloneDeep(this.selectedNode.nodeData);
        this.isEditMode = true;
    }

    createGrouping (): void {
        const nodeObj = null as null;
        this.selectedNodeParent = getNode(
            this.selectedNode.childId,
            this.hierarchyTiers,
            nodeObj,
            'childId'
        );
        this.editingNode = cloneDeep(newNode);
        this.editingNode.entityTypeId = 4;
        this.isEditMode = true;
    }

    async closeEditEntity (): Promise<void>  {

        if (this.editingNode.entityTypeId === 7) {
            // if closing an exposure, update the editing node to the custom investment
            const eData = await EntityDataApi.getData() as IClientEntityDto[];

            this.clientEntities = eData;
            const selectedNodeId = this.selectedNode.childId;

            if (selectedNodeId) {
            // update selected node to latest exposures
                this.selectedNode = getNode(
                    selectedNodeId,
                    this.hierarchyTiers,
                    null,
                    'childId'
                );
            }
            this.editCustomInvestment();
            return;
        }
        this.isEditMode = false;

        // reset selections
        this.editingNode = null;
        this.selectedNode = null;
        this.selectedNodeParent = null;
        // get latest data
        await this.getViewData();
    }

    async saveNewExposureAsChild (childIds: number[], newChildId: number): Promise<void>{
        let newChildIds: number[] = [];
        if (childIds) {
            newChildIds = [newChildId, ...childIds];
        } else {
            newChildIds = [newChildId];
        }
        const payload: IExposureDto = {
            portfolioId: this.portfolioId,
            clientId: this.$store.state.selectedScheme.clientEntityID,
            entityNameId: this.selectedNode.childId,
            childIds: newChildIds,
        };
        await HierarchyApi.updateEntityFixedRelationships(payload);

        const eData = await EntityDataApi.getData() as IClientEntityDto[];


        if (eData === undefined) {
            this.isLoading = false;
            return;
        };

        this.clientEntities = eData;

        const selectedNodeId = this.selectedNode.childId;
        if (selectedNodeId) {
            // update selected node to latest exposures
            this.selectedNode = getNode(
                selectedNodeId,
                this.hierarchyTiers,
                null,
                'childId'
            );
        }
        const exposure = this.selectedNode.exposures.find((e) => e.id === newChildId);
        this.editingNode = cloneDeep(exposure);
    }

    async saveNew (entity: IClientEntity): Promise<void> {
        this.isLoading = true;

        const create: ICreateClientEntityAndSetDefaultsRequestDto = {
            name: entity.name,
            typeId: entity.entityTypeId,
        };

        const responseEntity = await EntityDataApi.createClientEntityAndSetDefaults(create);

        if (entity.entityTypeId === 7) {
            const childIds: number[]  = this.selectedNode?.nodeData?.fixedRelationships?.childIds ?? [];
            try {
                await this.saveNewExposureAsChild(childIds, responseEntity.id);
            } catch (error) {
                this.$store.dispatch('pushNetworkErrorMessage', 'Error saving exposure');
                this.closeEditEntity();
            } finally {
                this.isLoading = false;
            }
            return;
        }


        this.clientEntities.push(responseEntity);

        const hier: ICreateHierarchyByDateDto & IHierarchyByDateDto = this.hierarchyData.find(
            (h) => h.datePeriodId == this.selectedNodeParent.datePeriodId
        );

        hier.relationships.push({
            clientRelationshipsId: this.changeId,
            parentEntityNameId: this.selectedNodeParent.childId,
            childEntityNameId: responseEntity.id,
            childEntityNameOrderBy: 0,
            datePeriodId: hier.datePeriodId,
            effectiveDate: hier.effectiveDate,
        });

        this.changeId -= 1;

        const saveData = {
            hierarchyId: hier.datePeriodId,
            dateEffectiveFrom: hier.effectiveDate,
            hierarchyData: hier.relationships,
        };

        await HierarchyApi.saveData(saveData);
        if (responseEntity.id) {
            // update selected node to new node
            this.selectedNode = getNode(
                responseEntity.id,
                this.hierarchyTiers,
                null,
                'childId'
            );
        }

        this.updateHierarchyDataClone();

        this.editingNode = cloneDeep(responseEntity);

        this.isLoading = false;
    }

    public closeClientSetup () : void {
        this.showClientSetup = false;
    }

    /**
     * highlights the array items on initial load
     * https://ej2.syncfusion.com/vue/documentation/treeview/multiple-selection#selected-nodes
     */

    get treeViewRootNodeId (): number[] {
        if (this.fields.dataSource.length === 0) return [];
        return [this.fields.dataSource[0].id];
    }

    setDeleteModal (): void {
        this.deleteModal = {
            active: true,
            deleteKeyword: this.selectedNode.name,
        };
    }

    discardDeleteModal (): void {
        this.deleteModal = {
            active: false,
            deleteKeyword: '',
        };
    }

    confirmDelete (): void {
        if (this.deleteModal.active) {
            this.deleteFund();
        }
        this.discardDeleteModal();
    }

    logout (): void {
        this.$router.push('/');
    }
}

