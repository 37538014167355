var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ticker-details-container" },
    [
      _vm.showMainDisplay
        ? _c(
            "div",
            { staticClass: "ticker-details-wrapper" },
            [
              _c(
                "div",
                { staticClass: "ticker-details-heading flex" },
                [
                  _c("h2", [
                    _vm._v(_vm._s(_vm.modalHeading)),
                    _vm.isTickerTypeBenchmark
                      ? _c("span", [_vm._v(" -")])
                      : _vm._e(),
                  ]),
                  _vm.isTickerTypeBenchmark
                    ? [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.lazy.trim",
                              value: _vm.benchmark.benchmarkName,
                              expression: "benchmark.benchmarkName",
                              modifiers: { lazy: true, trim: true },
                            },
                          ],
                          staticClass: "benchmark-name-input",
                          attrs: { type: "text" },
                          domProps: { value: _vm.benchmark.benchmarkName },
                          on: {
                            change: function ($event) {
                              _vm.$set(
                                _vm.benchmark,
                                "benchmarkName",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm.benchmark.isPrimaryBenchmark
                          ? _c("span", { staticClass: "primary-label" }, [
                              _vm._v("Primary"),
                            ])
                          : _c(
                              "button",
                              {
                                staticClass: "btn btn--default btn--small",
                                attrs: { type: "button" },
                                on: { click: _vm.handlePrimary },
                              },
                              [_vm._v("Make Primary")]
                            ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "details flex" }, [
                _c(
                  "div",
                  { staticClass: "left-content effective-date-holder" },
                  [
                    _c("div", { staticClass: "content-heading" }, [
                      _vm._v("Effective Date"),
                    ]),
                    _vm.localTickerDetails.length > 0
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.sortedEffectiveDate,
                            function (details, dateIndex) {
                              return _c("effective-date", {
                                key: dateIndex + details.effectiveDate,
                                staticClass: "effective-date-wrapper",
                                class: {
                                  "active-date":
                                    _vm.currentDateIndex === dateIndex,
                                },
                                attrs: {
                                  "effective-date": details.effectiveDate,
                                  details: _vm.sortedEffectiveDate,
                                  "date-index": dateIndex,
                                  "current-date-index": _vm.currentDateIndex,
                                  disabled:
                                    _vm.tickerType === "strategicAllocation",
                                },
                                on: {
                                  selectEffectiveDate: _vm.selectEffectiveDate,
                                  updateExistingEffectiveDate:
                                    _vm.updateExistingEffectiveDate,
                                  deleteEffectiveDate: _vm.deleteEffectiveDate,
                                  toggleDeleteDateDisabled:
                                    _vm.toggleDeleteDateDisabled,
                                },
                              })
                            }
                          ),
                          1
                        )
                      : _vm._e(),
                    _vm.showAddNewEffectiveDatePicker &&
                    _vm.tickerType !== "strategicAllocation"
                      ? _c(
                          "div",
                          { staticClass: "effective-date-wrapper flex" },
                          [
                            _c("date-picker-wrapper", {
                              attrs: {
                                tickerAllocations: _vm.localTickerDetails,
                                currentDateIndex: -1,
                              },
                              on: {
                                cancelDatePicker: function ($event) {
                                  _vm.showAddNewEffectiveDatePicker = false
                                },
                                updateEffectiveDate: _vm.updateNewEffectiveDate,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.showAddNewEffectiveDatePicker &&
                    !_vm.isTickerTypeStrategicAllocation
                      ? _c(
                          "p",
                          {
                            staticClass: "add-new",
                            on: { click: _vm.addNewEffectiveDate },
                          },
                          [_vm._v("Add effective date")]
                        )
                      : _vm._e(),
                  ]
                ),
                _c("div", { staticClass: "right-content" }, [
                  !_vm.isTickerTypeStrategicAllocation
                    ? _c("div", { staticClass: "right-content-inner flex" }, [
                        _vm.localTickerDetails.length > 0
                          ? _c("table", { staticClass: "ticker-table" }, [
                              _c("thead", [
                                _c(
                                  "tr",
                                  [
                                    _c("th", {
                                      staticStyle: { width: "auto" },
                                    }),
                                    _vm._l(
                                      _vm.tableHeadings,
                                      function (heading) {
                                        return _c(
                                          "th",
                                          { key: heading.key },
                                          [
                                            _vm._v(
                                              " " + _vm._s(heading.name) + " "
                                            ),
                                            heading.tooltipContent
                                              ? _c("font-awesome-icon", {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        heading.tooltipContent,
                                                      expression:
                                                        "heading.tooltipContent",
                                                    },
                                                  ],
                                                  staticClass: "fa-xs",
                                                  attrs: {
                                                    icon: [
                                                      "fad",
                                                      "info-circle",
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.activeTickerAllocationDetails,
                                  function (allocation, allocationIndex) {
                                    return _c(
                                      "tr",
                                      { key: allocationIndex },
                                      [
                                        _c("td", [
                                          allocationIndex !== 0
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: "Delete row",
                                                      expression:
                                                        "'Delete row'",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "ticker-table-icon",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteTickerAllocations(
                                                        allocationIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "fal",
                                                        "trash-alt",
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "div",
                                            { staticClass: "flex-container" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: "Ticker search",
                                                      expression:
                                                        "'Ticker search'",
                                                    },
                                                  ],
                                                  staticClass: "look-up-button",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.toggleTickerModal(
                                                        true,
                                                        allocationIndex,
                                                        allocation.tickerId.toUpperCase(),
                                                        allocation.valueType
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["fal", "search"],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "sr-only",
                                                  attrs: {
                                                    for: "ticker-id-choice",
                                                  },
                                                },
                                                [_vm._v("Choose a ticker:")]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.getTickerTooltip(
                                                      allocation.tickerId.toUpperCase()
                                                    ),
                                                    expression:
                                                      "getTickerTooltip(allocation.tickerId.toUpperCase())",
                                                  },
                                                ],
                                                staticClass: "ticker-id",
                                                attrs: {
                                                  list: "ticker-ids",
                                                  id: "ticker-id-choice",
                                                },
                                                domProps: {
                                                  value:
                                                    allocation.tickerId.toUpperCase(),
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.updateTickerAllocations(
                                                      allocationIndex,
                                                      "tickerId",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "datalist",
                                                { attrs: { id: "ticker-ids" } },
                                                _vm._l(
                                                  _vm.tickerIdsArray,
                                                  function (tickerId) {
                                                    return _c("option", {
                                                      key: tickerId,
                                                      attrs: {
                                                        label: tickerId,
                                                      },
                                                      domProps: {
                                                        value: tickerId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ticker-value-type",
                                            },
                                            [
                                              _c("ticker-value-type", {
                                                attrs: {
                                                  tickerId: allocation.tickerId,
                                                  value: allocation.valueType,
                                                },
                                                on: {
                                                  "update:change": function (
                                                    $event
                                                  ) {
                                                    return _vm.updateTickerAllocations(
                                                      allocationIndex,
                                                      "valueType",
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "text-input-with-formatted-overlay",
                                              {
                                                attrs: {
                                                  "format-type": "performance",
                                                  value:
                                                    allocation.weight * 100,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.updateTickerAllocations(
                                                      allocationIndex,
                                                      "weight",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "text-input-with-formatted-overlay",
                                              {
                                                attrs: {
                                                  "format-type": "multiple",
                                                  value:
                                                    allocation.returnMultiple,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.updateTickerAllocations(
                                                      allocationIndex,
                                                      "returnMultiple",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "text-input-with-formatted-overlay",
                                              {
                                                attrs: {
                                                  "format-type": "premium",
                                                  value:
                                                    allocation.premium * 100,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.updateTickerAllocations(
                                                      allocationIndex,
                                                      "premium",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.applyMultiCurrencyFeatureForTickerDetails
                                          ? [
                                              _c("td", [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      allocation.tickerBaseCurrency
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _c("td", [
                                                _vm.isConvertibleToBaseCurrency(
                                                  allocation
                                                )
                                                  ? _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            allocation.shouldConvertToBaseCurrency,
                                                          expression:
                                                            "allocation.shouldConvertToBaseCurrency",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          allocation.shouldConvertToBaseCurrency
                                                        )
                                                          ? _vm._i(
                                                              allocation.shouldConvertToBaseCurrency,
                                                              null
                                                            ) > -1
                                                          : allocation.shouldConvertToBaseCurrency,
                                                      },
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$a =
                                                                allocation.shouldConvertToBaseCurrency,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    allocation,
                                                                    "shouldConvertToBaseCurrency",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    allocation,
                                                                    "shouldConvertToBaseCurrency",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                allocation,
                                                                "shouldConvertToBaseCurrency",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                          function ($event) {
                                                            return _vm.updateTickerAllocations(
                                                              allocationIndex,
                                                              "shouldConvertToBaseCurrency",
                                                              allocation.shouldConvertToBaseCurrency
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    })
                                                  : _c("span", [
                                                      _vm._v(" n/a "),
                                                    ]),
                                              ]),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  }
                                ),
                                0
                              ),
                              _c("tfoot", [
                                _c("tr", [
                                  _c("td", { attrs: { colspan: "6" } }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "add-new",
                                        attrs: { type: "button" },
                                        on: { click: _vm.addTickerAllocations },
                                      },
                                      [_vm._v("Add new Ticker setting")]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _c("div", { staticClass: "content-inner" }, [
                              _vm._v("Please add an effective date"),
                            ]),
                        _c(
                          "div",
                          { staticClass: "flex right-content-bottom" },
                          [
                            _vm.showError
                              ? _c(
                                  "div",
                                  {
                                    ref: "errorContainer",
                                    staticClass: "error",
                                  },
                                  _vm._l(
                                    _vm.errorMessages,
                                    function (errorMessage, index) {
                                      return _c(
                                        "span",
                                        { key: errorMessage + index },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              errorMessage ||
                                                "Please check inputs"
                                            )
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ])
                    : _c("div", { staticClass: "right-content-inner flex" }, [
                        _vm.localTickerDetails.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "content-inner flex--column flex--space-between flex",
                              },
                              [
                                _c("div", { staticClass: "heading" }, [
                                  _vm._v("Strategic Allocation "),
                                  _c("div", { staticClass: "flex" }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          (
                                            _vm.localTickerDetails[
                                              _vm.currentDateIndex
                                            ].tickerAllocations[0]
                                              .allocationValue * 100
                                          ).toFixed(2)
                                        ) + "%"
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "link--text" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "You can edit Strategic Allocations on the "
                                      ),
                                    ]),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "link",
                                        attrs: { to: "/strategic-allocations" },
                                      },
                                      [_vm._v("Strategic Allocations")]
                                    ),
                                    _vm._v(" page "),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.localTickerDetails.length === 0
                          ? _c("div", [
                              _c(
                                "p",
                                { staticClass: "link--text" },
                                [
                                  _vm._v(
                                    " You currently have no Strategic Allocations in your setup. Please update "
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "link",
                                      attrs: { to: "/strategic-allocations" },
                                    },
                                    [_vm._v("Strategic Allocations")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                ]),
              ]),
              _c("div", { staticClass: "flex details-bottom" }, [
                _vm.showDeleteBenchmark
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.isDeleteDateDisabled
                              ? "Cannot delete Benchmark while an effective date is marked for deletion"
                              : "",
                            expression:
                              "isDeleteDateDisabled ? 'Cannot delete Benchmark while an effective date is marked for deletion' : ''",
                          },
                        ],
                        staticClass: "btn btn--default delete",
                        class: { "btn--disabled": _vm.isDeleteDateDisabled },
                        attrs: {
                          type: "button",
                          disabled: _vm.isDeleteDateDisabled,
                        },
                        on: { click: _vm.deleteBenchmark },
                      },
                      [_vm._v(" Delete ")]
                    )
                  : _vm._e(),
                !_vm.isTickerTypeStrategicAllocation
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn--default",
                        attrs: { type: "button" },
                        on: { click: _vm.saveChanges },
                      },
                      [_vm._v("Save")]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "btn btn--default discard",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("discard-ticker-changes")
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
              ]),
              _vm.deleteModal.active && !_vm.isTickerTypeStrategicAllocation
                ? _c("BaseModal", {
                    class: { "show-modal": _vm.deleteModal.active },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _c(
                                "ConfirmDeletion",
                                {
                                  attrs: {
                                    "delete-keyword":
                                      _vm.deleteModal.deleteKeyword,
                                  },
                                  on: {
                                    discardDeleteModal: _vm.discardDeleteModal,
                                    confirmDelete: _vm.confirmDelete,
                                  },
                                },
                                [
                                  _vm.isTickerTypeBenchmark
                                    ? _c("p", { staticClass: "delete-text" }, [
                                        _vm._v(
                                          " You're about to delete " +
                                            _vm._s(
                                              _vm.deleteModal.deleteKeyword
                                            ) +
                                            ", this will also delete all associated performance overrides. "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3476835252
                    ),
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm.showTickerIdSearch
        ? _c("ticker-details-list-available", {
            attrs: {
              "ticker-id": _vm.selectedTickerId,
              "ticker-value-type": _vm.selectedTickerValueType,
            },
            on: {
              closeModal: function ($event) {
                return _vm.toggleTickerModal(false, -1, null, null)
              },
              selectTicker: _vm.selectTicker,
            },
          })
        : _vm.showManagerResearch
        ? _c("research-managers", {
            attrs: {
              "manager-name": _vm.localManagerName,
              "research-view": _vm.researchView,
              "previously-selected-manager": _vm.selectedManagerFund,
            },
            on: {
              "close-modal": _vm.closeManagerModal,
              "delete-association": _vm.deleteAssociation,
              "select-manager": _vm.selectManager,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }