











































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseModal from '@/components/BaseModal.vue';
import TickerDetails from '@/components/TickerDetails.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import VTooltip from 'v-tooltip';
import { IClientEntity } from '@/interfaces/entity/IClientEntity';
import  BaseButton  from '@/components/BaseElements/BaseButton.vue';
import ConfirmDeletion from '@/components/ConfirmDeletion.vue';


Vue.use(VTooltip);

@Component({
    components: {
        BaseModal,
        TickerDetails,
        LoadingOverlay,
        BaseButton,
        ConfirmDeletion
    },
})
export default class CustomInvestmentExposures extends Vue {

    @Prop({ type: [Array, null], required: true }) exposures: Array<IClientEntity> | null

    @Prop({ type: Boolean, required: false, default: false }) isExposureAPIPending: boolean

    MAX_BENCHMARKS_COUNT = 3;

    BENCHMARK_RULES_TEXT = 'The Benchmark rule is assumed to be the same as the Funding Notional'

    deleteModal = {
        active: false,
        deleteKeyword: '',
        deleteById: -1
    };

    discardDeleteModal (): void {
        this.resetDeleteModal();
    }

    confirmDelete (): void {
        this.deleteExposureById(this.deleteModal.deleteById);
    }

    showConfirmDeleteModal (exposureId: number | null, benchmarkName: string): void {
        this.deleteModal.active = true;
        this.deleteModal.deleteById = exposureId;
        this.deleteModal.deleteKeyword = benchmarkName;
    }

    resetDeleteModal (): void {
        this.deleteModal.active = false;
        this.deleteModal.deleteById = -1;
        this.deleteModal.deleteKeyword = '';
    }

    openExposureById (exposureId: number | null): void {
        this.$emit('open-exposure-by-id', exposureId);
        this.resetDeleteModal();
    }

    openFundingNotional (): void {
        this.$emit('open-funding-notional');
        this.resetDeleteModal();
    }

    deleteExposureById (exposureId: number | null): void {
        this.$emit('delete-exposure-by-id', exposureId);
        this.resetDeleteModal();
    }


}
