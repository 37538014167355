var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ticker-details-wrapper" }, [
    _vm._m(0),
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "selectors-row" }, [
        _c("div", { staticClass: "search-wrapper" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            staticClass: "search",
            attrs: {
              type: "text",
              placeholder: "Type to filter tickers by ID or description...",
            },
            domProps: { value: _vm.search },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.search = $event.target.value
                },
                _vm.updateShowCount,
              ],
            },
          }),
        ]),
        _c("p", { staticClass: "text-right" }, [
          _c("small", [
            _vm._v(
              "Showing " +
                _vm._s(_vm.showCountText) +
                " of " +
                _vm._s(_vm.filteredListLength) +
                " results"
            ),
          ]),
        ]),
        !_vm.filteredListLength
          ? _c("p", [
              _vm._v(
                "No tickers match " + _vm._s(_vm.search) + ", please try again"
              ),
            ])
          : _c(
              "div",
              [
                _c(
                  "transition-group",
                  { attrs: { name: "fade", mode: "out-in", tag: "div" } },
                  _vm._l(
                    _vm.filteredList.slice(0, _vm.showCount),
                    function (ticker) {
                      return _c(
                        "div",
                        {
                          key: ticker.ticker,
                          staticClass: "card",
                          class: { selected: _vm.selectedOption === ticker },
                          attrs: { tabindex: "0" },
                          on: {
                            click: function ($event) {
                              return _vm.cardClick(ticker)
                            },
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.cardClick(ticker)
                            },
                          },
                        },
                        [
                          _c("div", [
                            _c("p", { staticClass: "text-right" }, [
                              _vm._v(" Ticker ID: "),
                              _c("b", [_vm._v(_vm._s(ticker.ticker))]),
                            ]),
                            _c("p", [
                              _c("i", [
                                _c("small", [
                                  _vm._v(_vm._s(ticker.description)),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                ),
                _vm.showMoreButton
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn--next",
                        attrs: { type: "button" },
                        on: { click: _vm.increaseCount },
                      },
                      [
                        _vm._v(
                          "Next " +
                            _vm._s(_vm.SHOW_COUNT_INCREMENT) +
                            " results"
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
      ]),
      _c("div", { staticClass: "ticker-info-box" }, [
        _c("p", { staticClass: "description-title" }, [
          _vm._v(
            _vm._s(
              _vm.selectedOption
                ? "Ticker details"
                : "Select a ticker for more details"
            )
          ),
        ]),
        _vm.selectedOption
          ? _c("div", [
              _c("label", [_vm._v("Ticker ID")]),
              _c("div", { staticClass: "ticker-info-label" }, [
                _vm._v(_vm._s(_vm.selectedOption.ticker)),
              ]),
              _c("label", [_vm._v("Ticker Description")]),
              _c("div", { staticClass: "ticker-info-label" }, [
                _vm._v(_vm._s(_vm.selectedOption.description)),
              ]),
              _c("label", [_vm._v("Value Type")]),
              _c(
                "div",
                { staticClass: "ticker-info-label" },
                [
                  _c("TickerValueType", {
                    attrs: {
                      value: _vm.tickerValueTypeLocal,
                      tickerId: _vm.selectedOption.ticker,
                    },
                    on: {
                      "update:change": function (newValue) {
                        return (_vm.tickerValueTypeLocal = newValue)
                      },
                    },
                  }),
                ],
                1
              ),
              _c("label", [_vm._v("Data Date Range")]),
              _c("div", { staticClass: "ticker-info-label" }, [
                _vm._v(_vm._s(_vm.dateRangeString)),
              ]),
              _c("label", [_vm._v("Base Currency")]),
              _c("div", { staticClass: "ticker-info-label" }, [
                _vm._v(_vm._s(_vm.selectedOption.baseCurrency)),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "flex details-bottom" }, [
      _c(
        "div",
        {
          staticClass: "btn btn--default discard",
          on: {
            click: function ($event) {
              return _vm.$emit("closeModal")
            },
          },
        },
        [_vm._v("Cancel")]
      ),
      _c(
        "div",
        {
          staticClass: "btn btn--default",
          class: { disabled: !_vm.selectedOption },
          on: { click: _vm.saveChanges },
        },
        [_vm._v("Select")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ticker-details-heading flex" }, [
      _c("h2", [_vm._v("Available Tickers")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }