import AssetAndMarketValueItem from '@/models/AssetAndMarketValueItem';
import dayjs, { Dayjs } from 'dayjs';

export const compare = (
    itemA: AssetAndMarketValueItem<number | string, null | number> | any,
    itemB: AssetAndMarketValueItem<number | string, null | number> | any
): number => {
    let sortedDate: number;
    const date1: Dayjs = dayjs(itemA.effectiveDate);
    const date2: Dayjs = dayjs(itemB.effectiveDate);

    sortedDate = +date1 - +date2;
    if (sortedDate === 0) {
        if ((itemA.type as string) < (itemB.type as string)) {
            sortedDate = -1;
        }
    }

    return sortedDate;
};

export const compareReverse = (
    itemA: AssetAndMarketValueItem<number | string, null | number> | any,
    itemB: AssetAndMarketValueItem<number | string, null | number> | any
): number => {
    let sortedDate: number;
    const date1: Dayjs = dayjs(itemA.effectiveDate);
    const date2: Dayjs = dayjs(itemB.effectiveDate);

    sortedDate = +date2 - +date1;
    if (sortedDate === 0) {
        if ((itemA.type as string) < (itemB.type as string)) {
            sortedDate = -1;
        }
    }

    return sortedDate;
};
